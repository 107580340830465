import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { FdtCheckboxComponent, FdtDropdownModule, FdtInputComponent } from '@1stdigital/ng-sdk/controls';
import { DropdownItem } from '@1stdigital/ng-sdk/core';
import { FdtFormFieldModule } from '@1stdigital/ng-sdk/form-field';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { ToggleGroupComponent, ToggleItemContainerComponent } from '@app/shared/toggle-group';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'app-dev',
  standalone: true,
  imports: [
    FdtIconModule, ReactiveFormsModule, FdtDropdownModule, FdtFormFieldModule, FdtButtonModule,
    FdtInputComponent, FdtCheckboxComponent, ToggleGroupComponent, ToggleItemContainerComponent,
    MatRadioModule],
  templateUrl: './dev.component.html',
  styleUrl: './dev.component.scss',
})
export class DevComponent {
  dataSource!: DropdownItem[];
  formGroup = new FormGroup({
    dropdown: new FormControl(['item4'], {
      validators: [Validators.required],
    }),
  });


  // Disabled form inputs - start

  radioItems: DropdownItem<number>[] = [{label: 'label 1', value: 1}, {label: 'label 2', value: 2}]
  radioForm = new FormGroup({
    radio: new FormControl({value: 1, disabled: false}),
    radioDisabledWithValue: new FormControl({value: 1, disabled: true}),
    radioDisabledWithoutValue: new FormControl({value: undefined, disabled: true}),
  });

  // Disabled form inputs - end

  constructor() {
    setTimeout(() => {
      this.dataSource = [
        {
          label: 'Item 1',
          value: 'item1',
        },
        {
          label: 'Item 2',
          value: 'item2',
        },
        {
          label: 'Item 3',
          value: 'item3',
        },
        {
          label: 'Item 4',
          value: 'item4',
        },
        {
          label: 'Item 5',
          value: 'item5',
        },
        {
          label: 'Item 6',

          value: 'item6',
        },
        {
          label: 'Item 7',
          value: 'item7',
        },
        {
          label: 'Item 8',
          value: 'item8',
        },
        {
          label: 'Item 9',
          value: 'item9',
        },
        {
          label: 'Item 10',
          value: 'item10',
        },
      ];
    }, 1000);
  }
}
