import { Component } from '@angular/core';

/**
 *
 * The purpose of this component is to be a temporary chain
 * when you need to redirect to exactly same route,
 * and  you don't know current user location to use another strategies.
 * At same time you don't want to use something like location.reload()
 * So you use intermediate route, that doesn't take additional user time,
 * since this component supposed to be absolutely empty.
 *
 * Note that onSameUrlNavigation only triggers guards and resolvers
 *
 * @example
 * this.router.navigate(['noop-component']).then(() => this.router.navigate(['destination']));
 * // Another strategy could be
 * onButtonClick() {
 *     this.router.routeReuseStrategy.shouldReuseRoute = function () {
 *         return false;
 *     }
 *     this.router.onSameUrlNavigation = 'reload';
 *     this.router.navigate('/myroute', { queryParams: { index: 1 } });
 * }
 */

@Component({
  selector: 'app-noop-component',
  standalone: true,
  imports: [],
  templateUrl: './noop.component.html',
  styleUrl: './noop.component.scss',
})
export class NoopComponent {}
