import { InjectionToken } from '@angular/core';

import { IconName } from '@1stdigital/ng-sdk/core';
import { ReplaySubject } from 'rxjs';

export interface SingleToggleItemConfig {
  label?: string;
}

export interface SimpleToggleButtonItemConfig extends SingleToggleItemConfig {
  /**
   * @deprecated
   */
  badge?: string | number;

  /**
   * @deprecated
   */
  icon?: IconName;
}

export interface SingleToggleItemComponent<T = unknown> {
  disabled: boolean;
  isActive: boolean;
  /**
   * Toggle items may contain additional config
   * like labels, icons, etc
   */
  config?: SimpleToggleButtonItemConfig;
  /**
   * Mandatory field to recognize different elements in group
   * Each value should be unique
   */
  value: T;
  /**
   * Reference to the parent's ReplaySubject item
   */
  parentValueChanges$: ReplaySubject<T>;
}

export interface ToggleItemRowComponent<T = unknown> {
  /**
   * Reference to the parent's ReplaySubject item
   */
  parentValueChanges$: ReplaySubject<T>;
}

export const FDT_SINGLE_TG_ITEM = new InjectionToken<SingleToggleItemComponent>('SingleToggleItemComponent');
export const FDT_TG_ITEM_ROW = new InjectionToken<ToggleItemRowComponent>('ToggleItemRowComponent');
