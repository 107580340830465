import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ClientService } from '@app/core/services/client.service';

@Component({
  selector: 'app-modal-entity-caption',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-entity-caption.component.html',
  styleUrl: './modal-entity-caption.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEntityCaptionComponent {
  clientName!: string;
  entityPackageName!: string;

  constructor(private clientContextService: ClientService) {}

  client$ = this.clientContextService.activeClient$;
  serviceEntity$ = this.clientContextService.activeServiceEntity$;
}
