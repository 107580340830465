import { Injectable } from '@angular/core';

import { BaseApiService } from '@1stdigital/ng-sdk/core';
import { EnvironmentLoaderService } from '@app/core/services';
import { Observable } from 'rxjs';

export interface UserInfoForClientResponse {
  isInstructionApprover: boolean;
  isCardHolder: boolean;
  isCardAdmin: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserInfoApiService extends BaseApiService {
  userInfo?: UserInfoForClientResponse;

  constructor(private envService: EnvironmentLoaderService) {
    super();
  }

  getUserInfoResponse(): Observable<UserInfoForClientResponse> {
    return this.get('user-information-on-client');
  }

  setUserInfo(userInfo: UserInfoForClientResponse): void {
    this.userInfo = userInfo;
  }

  getUserInfo(): UserInfoForClientResponse | undefined {
    return this.userInfo;
  }
}
