import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { ClientStatus, ClientStoredData, LegalEntity } from '@app/core/models';
import { ClientService } from '@app/core/services/client.service';
import { LayoutApiService } from '@app/layout/layout-api.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-inactive-client',
  standalone: true,
  imports: [FdtButtonModule, FdtIconModule, TitleCasePipe],
  templateUrl: './inactive-client.component.html',
  styleUrl: './inactive-client.component.scss',
})
export class InactiveClientComponent implements OnInit {
  client: ClientStoredData | null = null;
  clientType?: LegalEntity;
  clientStatus?: ClientStatus;

  constructor(
    private clientService: ClientService,
    private layoutService: LayoutApiService,
    private authService: MsalService
  ) {}

  ngOnInit(): void {
    this.client = this.clientService.getClientSelection();
    this.layoutService.getClientAccessorList().subscribe({
      next: (clients) => {
        this.clientStatus = clients.find((client) => client.name === this.client?.clientName)?.status;
        this.clientType = clients.find((client) => client.name === this.client?.clientName)?.type;
      },
    });
  }

  onBackToSignIn(): void {
    this.authService.loginRedirect();
  }
}
