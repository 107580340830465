import { JsonPipe, NgClass } from '@angular/common';
import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  signal,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { DropdownValueChange, FdtDropdownComponent } from '@1stdigital/ng-sdk/controls';
import { DropdownItem, IconName } from '@1stdigital/ng-sdk/core';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { FdtPopover, FdtPopoverModule } from '@1stdigital/ng-sdk/popover';
import { DocumentType } from '@app/core/models/document-type.enum';
import { DocumentTypeGroup } from '@app/core/models/document-type-group.type';
import { FileStorageApiService } from '@app/core/services/file-storage-api.service';
import { DataUploadedService } from '@app/shared/file-upload/services/data-transfer.service';
import { Subject, takeUntil } from 'rxjs';
import { SnackBarService } from 'src/app/notifications/snack-bar.service';

import { FileSizePipe } from '../pipes/file-size.pipe';
import {
  FileParameter,
  NewFileStorageService,
  TemporaryUploadedFileDto,
  UploadFileTemporaryRequest,
} from '../services/file-storage.service';

export interface FileSelectEvent {
  target: { files: FileList };
}

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [
    FdtIconModule,
    FdtButtonModule,
    NgClass,
    FileSizePipe,
    FdtDropdownComponent,
    FdtPopoverModule,
    MatProgressSpinnerModule,
    JsonPipe,
  ],
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.scss',
})
export class FileUploaderComponent implements OnChanges, OnInit, OnDestroy, FileUploaderComponentInput {
  @ViewChild('dropzone') dropzone!: ElementRef;
  @ViewChildren(FdtPopover) fdtPopovers?: QueryList<FdtPopover>;

  @Input() control?: AbstractControl | null;
  @Input() multiple?: boolean = true;
  @Input() applicationId?: number;
  @Input() clientId?: number;
  @Input() label?: string;
  @Input() legalEntityId?: number;
  @Input() documentType?: DocumentType;
  @Input() documentTypeGroup?: DocumentTypeGroup;
  @Input() temporaryUploaded?: TemporaryUploadedFileDto[];
  @Input() descInstruction = 'Allowed formats: PNG, JPG and PDF. Maximum 25MB';
  @Input() minSize: number = MIN_SIZE;
  @Input() maxSize: number = MAX_SIZE;
  @Input() fileTypes: string[] = [];
  @Input() instructionId?: number;
  @Input() reset: boolean = false;
  @Input() isLogo: boolean = false;
  @Input() descInstructionForLogo = 'Allowed formats: PNG, JPG. Maximum 25MB';
  @Output() upload: EventEmitter<TemporaryUploadedFileDto[]> = new EventEmitter<TemporaryUploadedFileDto[]>();
  @Output() focused = new EventEmitter<void>();
  @Output() blurred = new EventEmitter<void>();

  isDropzoneHovered: boolean = false;
  selectedFilesSignal = signal<SelectedFile[]>([]);
  status = Status;

  numberOfUploadInProgress = 0;

  fileTypeDropdownItems: DropdownItem[] = [...DOCUMENT_TYPE_DROPDOWN_ITEMS_ALL];

  private ngUnsub = new Subject<void>();

  constructor(
    private snackBarService: SnackBarService,
    private fileStorageApiService: FileStorageApiService,
    private fileStorageService: NewFileStorageService,
    private dataUploadedService: DataUploadedService
  ) {
    effect(() => {
      const selectedFiles = this.selectedFilesSignal();

      if (!selectedFiles.length) {
        return;
      }

      if (selectedFiles.some(({ file }) => file.documentType)) {
        return this.fdtPopovers?.forEach((popover) => popover.hide());
      }

      setTimeout(() => {
        const firstPopover = this.fdtPopovers?.first;
        firstPopover?.isOpen ? firstPopover.updatePosition() : firstPopover?.show();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.numberOfUploadInProgress !== 0) {
      // we ignore any changes while upload is in progress
      // this is required since when file is uploaded we trigger a change event;
      // our file-upload-control listen for change event but again trigger the change event so it
      // overides here the temporaryUploadedFiles BECAUSE
      // when we are triggering the event we are filtering the this.selectedFiles;
      return;
    }

    const reset = changes['reset']?.currentValue;
    const temporaryUploaded = changes['temporaryUploaded']?.currentValue;

    if (reset == true) {
      this.selectedFilesSignal.set([]);
    }

    if (temporaryUploaded && temporaryUploaded.length) {
      this.handleTemporaryUploaded();
    }
  }

  get acceptFile(): string[] {
    return this.fileTypes ? this.fileTypes : FILE_TYPES;
  }

  ngOnInit(): void {
    this.dataUploadedService.data().subscribe((data) => {
      const fieldId = data.fileId as number;

      if (this.selectedFilesSignal().length > 0 && fieldId >= 0) {
        this.selectedFilesSignal.update((files) =>
          files.map((file, id) => {
            if (id === fieldId) {
              return {
                ...file,
                value: data.getPercentDone() as number,
              };
            }

            return file;
          })
        );
      }
    });

    if (this.documentTypeGroup) {
      this.fileTypeDropdownItems = this.fileStorageApiService.getDocumentType(this.documentTypeGroup);
    }
  }

  onCustomClick(event?: MouseEvent): void {
    event?.stopPropagation();
    this.dropzone.nativeElement.click();
    this.focused.emit();
  }

  onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    this.isDropzoneHovered = true;
  }

  onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    this.isDropzoneHovered = false;
  }

  onDrop(evt: DragEvent): void {
    evt.preventDefault();
    this.isDropzoneHovered = false;
    const files = evt.dataTransfer?.files ?? new FileList();

    if (files.length) {
      this.fileInput({ target: { files } });
    }
  }

  private handleTemporaryUploaded(): void {
    if (this.temporaryUploaded?.length) {
      const updatedFiles = this.temporaryUploaded.map((x, i) => ({
        id: i,
        file: x,
        status: Status.Completed,
        displayFileName: x.fileName ?? x.fileToProcess?.name ?? x.key ?? 'File',
        value: 100,
        fileToProcess: x.fileToProcess,
      }));

      this.selectedFilesSignal.set(updatedFiles);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  onCancel(): void {
    this.blurred.emit();
  }

  fileInput(event: Event | FileSelectEvent): void {
    const fileList: FileList | null = (event.target as HTMLInputElement).files as FileList;

    for (let i = 0; i < fileList.length; i++) {
      const f = fileList.item(i);

      if (this.isFileSupported(f)) {
        this.handleFileUpload(f as File);
      } else {
        this.snackBarService.showError({
          caption: 'Please make sure your file is in JPG, PNG, PDF format and not larger than 25MB.',
          message: 'File hasn’t been uploaded',
        });
      }

      // Clean up the input value to allow selecting the same file and show error in case it is invalid;
      if (this.dropzone) {
        this.dropzone.nativeElement.value = '';
      }
    }
  }

  private handleFileUpload(f: File): void {
    const selectedFiles = this.selectedFilesSignal();

    if (selectedFiles.findIndex((sf) => sf.file.key === f.name && sf.file.size === f.size) === -1) {
      const file = {
        key: f.name, // was null
        size: f.size,
        contentType: f.type,
        documentType: this.documentType,
        label: this.label,
        fileToProcess: f,
      } as TemporaryUploadedFileDto;
      const selectedFile: SelectedFile = {
        id: selectedFiles.length,
        status: Status.New,
        file,
        displayFileName: f.name,
        key: f.name,
        value: 0,
        fileToProcess: f,
      };
      this.selectedFilesSignal.update((files) => [...files, selectedFile]);

      const reader = new FileReader();
      reader.onloadend = (): void => {
        this.numberOfUploadInProgress++;
        this.uploadDocumentToServer(f, selectedFile);
      };

      reader.readAsArrayBuffer(f);
    }
  }

  emitUploadEvent(): void {
    const filesToEmit = this.selectedFilesSignal()
      .filter((sf) => sf.status === Status.Completed && sf.file.key)
      .map((sf) => sf.file);
    this.upload.emit([...filesToEmit]);
  }

  isFileSupported(f: File | null): boolean {
    if (!f) {
      return false;
    }

    return FILE_TYPES.some((x) => x === f?.type) && f.size >= this.minSize && f.size <= this.maxSize;
  }

  getStatusIcon(status: Status): IconName {
    switch (status) {
      case Status.New:
        this.control?.markAsPending();
        return 'loopLeftLine';
      case Status.Completed:
        return 'checkLine';
      case Status.Failed:
        return 'alertLine';
      case Status.Deleting:
        return 'informationLine';
      default:
        return 'informationLine';
    }
  }

  removePendingStatus(): void {
    if (this.control?.pending) {
      this.control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  onPreviewClick(file: File): void {
    const fileUrl = URL.createObjectURL(file);
    window.open(fileUrl, '_blank');
  }

  onCloseClick(event: MouseEvent, sf: SelectedFile): void {
    event.stopPropagation();
    const filteredFiles = this.selectedFilesSignal().filter((f) => {
      return f.id !== sf.id;
    });
    this.selectedFilesSignal.set(filteredFiles);
    this.emitUploadEvent();
    this.ngUnsub.next();
    this.snackBarService.showSuccess({
      message: `Document has been deleted`,
    });
  }

  onDocumentTypeChange(sf: SelectedFile, $event: DropdownValueChange<never, string | undefined>): void {
    if (!$event.selected.length) {
      return;
    }

    if ($event.selected[0].value === sf.file.documentType) {
      return;
    }

    const documentType = $event.selected[0].value;
    this.selectedFilesSignal.update((selectedFiles) => {
      const f = selectedFiles.find(({ id }) => sf.id === id);

      if (f) {
        f.file.documentType = documentType as DocumentType;
      }

      return selectedFiles;
    });

    this.fdtPopovers?.forEach((popover) => popover.hide());

    this.emitUploadEvent();
  }

  private uploadDocumentToServer(f: File, selectedFile: SelectedFile): void {
    const fileModel: UploadFileTemporaryRequest = {
      formFile: { data: f, fileName: f.name } as FileParameter,
      size: f.size,
      contentType: f.type,
      //  DocumentType.Other might need to be removed after BE update the logic of generating file name;
      documentType: selectedFile.file.documentType ?? this.documentType ?? DocumentType.Other,
      applicationId: this.applicationId,
      uploadFileClientId: this.clientId,
      legalEntityId: this.legalEntityId,
      fileId: selectedFile.id,
    };

    this.fileStorageService
      .uploadFileTemporary(fileModel)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(
        (response) => {
          this.numberOfUploadInProgress--;
          selectedFile.file.key = response.key;
          selectedFile.status = Status.Completed;
          selectedFile.displayFileName = response.fileName || response.key || 'File';
          selectedFile.file.userId = response.userId;
          selectedFile.value = 100;
          selectedFile.key = response.key;
          this.removePendingStatus();
          this.emitUploadEvent();

          if (this.dropzone) {
            this.dropzone.nativeElement.value = '';
          }
        },
        (err) => {
          this.numberOfUploadInProgress--;
          selectedFile.status = Status.Failed;
          selectedFile.value = 100;
          this.removePendingStatus();
          this.emitUploadEvent();

          if (this.dropzone) {
            this.dropzone.nativeElement.value = '';
          }

          throw err;
        }
      );
  }
}

enum Status {
  New = 'new',
  Completed = 'completed',
  Failed = 'failed',
  Deleting = 'deleting',
}

interface SelectedFile {
  id: number;
  file: TemporaryUploadedFileDto;
  status: Status;
  displayFileName: string;
  value: number;
  key?: string;
  fileToProcess?: File;
}

export interface FileUploaderComponentInput {
  multiple?: boolean;
  applicationId?: number;
  clientId?: number;
  legalEntityId?: number;
  label?: string;
  documentType?: DocumentType;
  documentTypeGroup?: DocumentTypeGroup;
  temporaryUploaded?: TemporaryUploadedFileDto[];
  descInstruction: string;
  minSize: number;
  maxSize: number;
  fileTypes: string[];
  instructionId?: number;
  reset: boolean;
  isLogo: boolean;
  descInstructionForLogo: string;
}

export const MIN_SIZE = 1 * 1024;
export const MAX_SIZE = 25 * 1024 * 1024;
export const FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

export interface DocumentTypeDropdownItem {
  label: string;
  value: string;
}

const DOCUMENT_TYPE_DROPDOWN_ITEMS_ALL = [
  {
    label: 'Bank statement',
    value: 'bank-statement',
  },
  {
    label: 'Birth certificate',
    value: 'birth-certificate',
  },
  {
    label: 'Certificate of insurance',
    value: 'certificate-of-insurance',
  },
  {
    label: 'Certificate of naturalisation',
    value: 'certificate-of-naturalisation',
  },
  {
    label: 'Driving licence',
    value: 'driving-licence',
  },
  {
    label: 'Government letter',
    value: 'government-letter',
  },
  {
    label: 'Home office letter',
    value: 'home-office-letter',
  },
  {
    label: 'Immigration status document',
    value: 'immigration-status-document',
  },
  {
    label: 'Marriage certificate',
    value: 'marriage-certificate',
  },
  {
    label: 'National identity card',
    value: 'national-identity-card',
  },
  {
    label: 'National insurance card',
    value: 'national-insurance-card',
  },
  {
    label: 'Passport',
    value: 'passport',
  },
  {
    label: 'Payslip',
    value: 'payslip',
  },
  {
    label: 'Residence permit',
    value: 'residence-permit',
  },
  {
    label: 'Tax id',
    value: 'tax-id',
  },
  {
    label: 'Utility bill electric',
    value: 'utility-bill-electric',
  },
  {
    label: 'Utility bill gas',
    value: 'utility-bill-gas',
  },
  {
    label: 'Utility bill other',
    value: 'utility-bill-other',
  },
  {
    label: 'Work permit',
    value: 'work-permit',
  },
  {
    label: 'W8ben',
    value: 'w8ben',
  },
  {
    label: 'Loss us nationality',
    value: 'loss-us-nationality',
  },
  {
    label: 'Articles of association',
    value: 'articles-of-association',
  },
  {
    label: 'Certificate of incorporation',
    value: 'certificate-of-incorporation',
  },
  {
    label: 'Register of members',
    value: 'register-of-members',
  },
  {
    label: 'Register of directors',
    value: 'register-of-directors',
  },
  {
    label: 'Annual returns',
    value: 'annual-returns',
  },
  {
    label: 'Financial statements',
    value: 'financial-statements',
  },
  {
    label: 'Utility bill water',
    value: 'utility-bill-water',
  },
  {
    label: 'Utility bill landline phone',
    value: 'utility-bill-landline-phone',
  },
  {
    label: 'Utility bill internet',
    value: 'utility-bill-internet',
  },
  {
    label: 'Credit card statement',
    value: 'credit-card-statement',
  },
  {
    label: 'Tax receipt',
    value: 'tax-receipt',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Transaction',
    value: 'transaction',
  },
  {
    label: 'Utility bill mobile',
    value: 'utility-bill-mobile',
  },
  {
    label: 'Deposit slip',
    value: 'deposit-slip',
  },
  {
    label: 'Proof of identification of beneficial owner',
    value: 'proof-of-identification-of-beneficial-owner',
  },
  {
    label: 'Proof of identification of director',
    value: 'proof-of-identification-of-director',
  },
  {
    label: 'Proof of address of director',
    value: 'proof-of-address-of-director',
  },
  {
    label: 'Invoice',
    value: 'invoice',
  },
  {
    label: 'Service agreement',
    value: 'service-agreement',
  },
  {
    label: 'Certificate df incumbency',
    value: 'certificate-df-incumbency',
  },
  {
    label: 'Proof of source of funds',
    value: 'proof-of-source-of-funds',
  },
  {
    label: 'Copy of license for conducting regulated activities',
    value: 'copy-of-license-for-conducting-regulated-activities',
  },
  {
    label: 'Proof of address of beneficial owner',
    value: 'proof-of-address-of-beneficial-owner',
  },
  {
    label: 'Withdrawal completion slip',
    value: 'withdrawal-completion-slip',
  },
  {
    label: 'Payment completion slip',
    value: 'payment-completion-slip',
  },
  {
    label: 'Business registration',
    value: 'business-registration',
  },
];
