<!--Default slot for handling single items-->
@if (!useRowSlot) {
  <div class="fdt-toggle-group-single-item__container">
    <ng-content></ng-content>
  </div>
} @else {
  <!-- Slot to display preconfigured rows -->
  <div class="fdt-toggle-group-row__container">
    <ng-content select="[fdt-toggle-group-row]"></ng-content>
  </div>
}
