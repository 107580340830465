@if (multiple ? true : !selectedFilesSignal().length) {
  <button class="input-wrapper" (click)="onCustomClick($event)">
    <div
      id="dropzone"
      class="file-uploader"
      [ngClass]="{ hover: isDropzoneHovered, 'file-uploader--multiple': multiple }"
      (dragover)="onDragOver($event)"
      (drop)="onDrop($event)"
      (dragleave)="onDragLeave($event)"
    >
      @if (multiple) {
        <div class="file-uploader__icon">
          <fdt-icon size="20" name="uploadCloud2Line"></fdt-icon>
        </div>
        <div class="file-uploader__title">
          <!-- content is set via css-->
        </div>
        <div class="file-uploader__caption">
          <span> Supported formats: JPG, PNG, PDF </span>
          <span class="file-uploader__caption"> Maximum size of 1 file: 25 MB </span>
        </div>
      } @else {
        <div class="file-uploader--header">
          <div class="file-uploader__icon">
            <fdt-icon size="20" name="uploadCloud2Line"></fdt-icon>
          </div>
          Upload
        </div>
        <div class="file-uploader__caption">JPG, PNG, PDF, 25 MB max.</div>
      }
      <input
        #dropzone
        id="fileupload-dropzone"
        type="file"
        [multiple]="multiple"
        (change)="fileInput($event)"
        (cancel)="onCancel()"
        [accept]="acceptFile"
      />
    </div>
  </button>
}

@if (selectedFilesSignal().length) {
  <div
    class="file-list"
    [ngClass]="{ 'file-list--multiple': multiple }"
    (keyup)="$event.stopPropagation()"
    (click)="$event.stopPropagation()"
    tabindex="0"
  >
    @for (sf of selectedFilesSignal(); track sf.id) {
      <div class="file-uploader-item">
        <div class="file-uploader-item__header">
          <span class="file-uploader-item__status-icon {{ sf.status || 'new' }}">
            @if (sf.status === 'new') {
              <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
            } @else {
              <fdt-icon size="20" [name]="getStatusIcon(sf.status)"></fdt-icon>
            }
          </span>
          <span class="file-uploader-item__file-name">{{ sf.displayFileName }}</span>

          <div class="file-uploader-item__file-actions">
            @if (sf.status === status.Completed && sf.fileToProcess) {
              <button fdt-fab-button color="tertiary" (click)="onPreviewClick(sf.fileToProcess)">
                <fdt-icon name="externalLinkLine"></fdt-icon>
              </button>
            }
            <button fdt-fab-button color="tertiary" (click)="onCloseClick($event, sf)">
              <fdt-icon [name]="sf.status === status.New ? 'closeLine' : 'deleteBinLine'"></fdt-icon>
            </button>
          </div>
        </div>
        <div class="file-uploader-item__body">
          <span class="file-uploader-item__document-type">Document type</span>
          <div
            class="file-uploader-item__document-type-dropdown"
            [fdtPopover]="documentPopoverContent"
            fdtPopoverTrigger="manual"
            fdtPopoverPosition="right"
            [fdtPopoverCloseOutside]="false"
          >
            <fdt-dropdown
              (valueChange)="onDocumentTypeChange(sf, $event)"
              [multiple]="false"
              [search]="false"
              [value]="$any(sf.file.documentType)"
              [items]="fileTypeDropdownItems"
            >
              <ng-template #triggerTemplate let-dropdown>
                <button fdt-ghost-button>{{ dropdown.selection[0]?.label || 'Select document type' }}</button>
              </ng-template>
            </fdt-dropdown>
          </div>

          <ng-template #documentPopoverContent>
            <p class="file-uploader__popover">
              Please select a document type for each uploaded file to move to the next step
            </p>
          </ng-template>
        </div>
      </div>
    }
  </div>
}
