import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { FDT_SINGLE_TG_ITEM, SingleToggleItemComponent, ToggleGroupComponent } from '@app/shared/toggle-group';
import { distinctUntilChanged, Subscription } from 'rxjs';

@Component({
  selector: 'app-toggle-item-container',
  standalone: true,
  imports: [],
  providers: [{ provide: FDT_SINGLE_TG_ITEM, useExisting: ToggleItemContainerComponent }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.fdt-toggle-item-active]': 'isActive',
    '[class.fdt-toggle-item-disabled]': 'disabled',
  },
  templateUrl: './toggle-item-container.component.html',
  styleUrl: './toggle-item-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleItemContainerComponent<T = unknown> implements OnInit, OnDestroy, SingleToggleItemComponent<T> {
  isActive = false;
  @Input() disabled = false;
  @Input() value!: T;
  @Input() emitValueChangeOnClick = false;

  parentValueChanges$ = this.tgGroup.valueChanges$;
  private valueChangeSubscription?: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private tgGroup: ToggleGroupComponent<T>
  ) {}

  ngOnInit(): void {
    this.subscribeToParentValueChanges();
  }

  ngOnDestroy(): void {
    this.valueChangeSubscription?.unsubscribe();
  }

  @HostListener('click')
  onChildContentClick(): void {
    if (this.emitValueChangeOnClick && !this.disabled) {
      this.parentValueChanges$.next(this.value);
    }
  }

  subscribeToParentValueChanges(): void {
    this.valueChangeSubscription = this.parentValueChanges$.pipe(distinctUntilChanged()).subscribe((value) => {
      this.checkIsActive(value);
      this.cdr.markForCheck();
    });
  }

  checkIsActive(newValue: T): void {
    this.isActive = this.value === newValue;
  }
}
