import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AnalyticsProps } from '@app/core/models';
import { AnalyticsEvents } from '@app/core/models/analytics-events.type';
import { ClientService } from '@app/core/services/client.service';
import { EnvironmentLoaderService } from '@app/core/services/environment-loader.service';
import { LoggingService } from '@app/core/services/logging.service';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private mixpanelPromise = import('mixpanel-browser').then((m) => m.default);

  constructor(
    private envService: EnvironmentLoaderService,
    private clientService: ClientService,
    private loggingService: LoggingService
  ) {
    this.init();

    combineLatest([this.clientService.activeClient$, this.clientService.activeServiceEntity$])
      .pipe(takeUntilDestroyed())
      .subscribe(([client, serviceEntity]) => {
        this.mixpanelPromise.then((mixpanel) => {
          mixpanel.register({ clientId: client.id, clientType: client.type, serviceEntityName: serviceEntity?.name });
        });
      });
  }

  /**
   * Identify user in mixpanel by id
   */
  identify(id: string): void {
    this.mixpanelPromise.then((mixpanel) => {
      mixpanel.identify(id);
    });
  }

  /**
   * Reset identified user
   */
  reset(): void {
    this.mixpanelPromise.then((mixpanel) => {
      mixpanel.reset();
    });
  }

  /**
   * Push new action to mixpanel
   */
  track(name: AnalyticsEvents, props?: AnalyticsProps): void {
    this.mixpanelPromise.then((mixpanel) => {
      mixpanel.track(name, props);
    });
  }

  /**
   * Track view page action;
   */
  trackPageView(name: AnalyticsEvents, props?: AnalyticsProps): void {
    this.mixpanelPromise.then((mixpanel) => {
      mixpanel.track_pageview(props, { event_name: name });
    });
  }

  /**
   * Initialize mixpanel
   * @private
   */
  private init(): void {
    // dummy token which will be used to init mixpanel and disable it if token is not provided;
    const fallbackToken = 'token';
    const token = (this.envService.environment['mixpanelToken'] as string) ?? fallbackToken;
    this.mixpanelPromise.then((mixpanel) => {
      mixpanel.init(token, { track_pageview: 'full-url' });

      if (fallbackToken === token) {
        mixpanel.disable();
        this.loggingService.log('Mixpanel is not initialized. Token is not provided');
      }
    });
  }
}
