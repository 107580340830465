<div class="entity-switcher__container" [matTooltip]="tooltipByStatus[status] ?? ''" matTooltipPosition="above">
  <div class="entity-switcher__option">
    <div class="entity-switcher__option-label">
      {{ label }}
      @if (isDisabled) {
        <fdt-status-label
          class="entity-switcher__option-inactive-status"
          [status]="status"
          size="small"
          [config]="accountStatusConfig"
        ></fdt-status-label>
      }
    </div>

    @if (amount) {
      <div class="entity-switcher__amount">
        <app-display-amount colorScheme="mono-secondary" [value]="amount"></app-display-amount>
      </div>
    }
  </div>
</div>
