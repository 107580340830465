import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { distinctUntilChanged, ReplaySubject, Subject, takeUntil } from 'rxjs';

import { FDT_SINGLE_TG_ITEM, FDT_TG_ITEM_ROW, SingleToggleItemComponent, ToggleItemRowComponent } from '../models';

@Component({
  selector: 'fdt-toggle-group',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleGroupComponent),
      multi: true,
    },
  ],
  imports: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './toggle-group.component.html',
  styleUrl: './toggle-group.component.scss',
})
export class ToggleGroupComponent<T = unknown> implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() useRowSlot = false;
  value?: T;
  disabled: boolean = false;
  valueChanges$: ReplaySubject<T> = new ReplaySubject<T>(1);

  private cdr = inject(ChangeDetectorRef);
  private readonly destroy$ = new Subject<void>();

  @ContentChildren(FDT_SINGLE_TG_ITEM) singleItemChildren!: QueryList<SingleToggleItemComponent>;
  @ContentChildren(FDT_TG_ITEM_ROW) tgItemRow!: QueryList<ToggleItemRowComponent>;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onChange = (value: T) => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onTouched = () => {};

  ngOnInit(): void {
    this.valueChanges$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((value) => {
      this.onChange(value);
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(value: T): void {
    this.value = value;
    this.valueChanges$.next(value);
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
