<div class="fdt-inactive-client">
  <div class="fdt-inactive-client__container">
    <div class="fdt-inactive-client__content">
      <div class="fdt-inactive-client__title">
        {{ clientStatus | titlecase }} {{ clientType === 'institution' ? 'company' : '' }} account
      </div>
      <div class="fdt-inactive-client__description">
        <div>
          @switch (clientStatus) {
            @case ('dormant') {
              Dear user, your account and all related services have been dormant.
            }
            @case ('suspended') {
              Dear user, your account has been suspended due to inactivity.
            }
            @case ('closed') {
              Dear user, your account and all related services have been closed.
            }
          }
        </div>
        <div>
          Please get in touch with our client services team at
          <a href="mailto:cs@1stdigital.com" target="_blank" class="fdt-inactive-client__link">
            cs&#64;1stDigital.com
          </a>
          or your relationship manager for more information.
        </div>
      </div>
      <div class="fdt-inactive-client__client">
        <div class="fdt-inactive-client__client-content">
          <div class="fdt-inactive-client__client-avatar">
            @if (client?.clientType === 'individual') {
              <fdt-icon size="20" name="userLine"></fdt-icon>
            } @else {
              <fdt-icon size="20" name="buildingLine"></fdt-icon>
            }
          </div>
          <div>{{ client?.clientName }}</div>
        </div>
        <div
          class="fdt-inactive-client__client-content-status"
          [class.fdt-inactive-client__client-content-status--danger]="
            clientStatus === 'suspended' || clientStatus === 'closed'
          "
        >
          @switch (clientStatus) {
            @case ('dormant') {
              <fdt-icon size="12" name="moonFill"></fdt-icon>
            }
            @case ('suspended') {
              <fdt-icon size="12" name="spam3Fill"></fdt-icon>
            }
            @case ('closed') {
              <fdt-icon size="12" name="closeCircleFill"></fdt-icon>
            }
          }
          {{ clientStatus | titlecase }}
        </div>
      </div>
      <button fdt-button color="secondary" size="large" (click)="onBackToSignIn()">Back to Sign in</button>
    </div>
  </div>
  <div class="fdt-inactive-client__bg-image"></div>
</div>
