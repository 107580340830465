<div header class="modal-header">
  <div class="modal-header__caption">
    @if (client$ | async; as client) {
      {{ client.name }}
    }
    <div class="modal-header__divider"></div>
    @if (serviceEntity$ | async; as serviceEntity) {
      {{ serviceEntity.name }}
    }
  </div>
</div>
