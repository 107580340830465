import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DropdownItem } from '@1stdigital/ng-sdk/core';
import { Observable } from 'rxjs';

interface Country {
  name: string;
  dial_code: string;
  code: string;
  flag: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  countries: Country[] = [];

  constructor(private httpClient: HttpClient) {}

  getCountries(): Observable<Country[]> {
    return this.httpClient.get<Country[]>('assets/country-code.json');
  }

  setCountries(countries: Country[]): void {
    this.countries = countries;
  }

  getCountryDropdownItems(): DropdownItem[] {
    return this.countries.map((country: Country) => ({
      value: country.dial_code,
      label: `${country.flag} ${country.dial_code}`,
    }));
  }

  getCountry(code: string): Country | undefined {
    return this.countries.find((country: Country) => country.code === code);
  }
}
