import { inject } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { FileStorageApiService } from './services/file-storage-api.service';

export const documentTypesResolver = (): Observable<unknown> => {
  const fileStorageApiService = inject(FileStorageApiService);

  return fileStorageApiService.getDocumentTypes().pipe(
    tap((countries) => {
      fileStorageApiService.setDocumentTypes(countries);
    })
  );
};
