import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import { ButtonComponent } from '@1stdigital/ng-sdk/button';
import { IconComponent } from '@1stdigital/ng-sdk/icon';

@Component({
  selector: 'app-disclaimer-modal',
  standalone: true,
  imports: [ButtonComponent, IconComponent],
  templateUrl: './disclaimer-modal.component.html',
  styleUrl: './disclaimer-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisclaimerModalComponent {
  constructor(private dialogRef: MatDialogRef<DisclaimerModalComponent, boolean>) {}

  onCloseModal(): void {
    this.dialogRef.close(false);
  }
}
