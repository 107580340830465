import { inject } from '@angular/core';

import { UserInfoApiService } from '@app/core/services/user-info-api.service';
import { Observable, tap } from 'rxjs';

export const userInfoResolver = (): Observable<unknown> => {
  const userInfoApiService = inject(UserInfoApiService);

  return userInfoApiService.getUserInfoResponse().pipe(
    tap((userInfo) => {
      userInfoApiService.setUserInfo(userInfo);
    })
  );
};
