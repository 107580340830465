<div class="full-size-modal-wrapper">
  <div class="full-size-modal-wrapper__header">
    <!-- Navigate to home and close the modal -->
    <button
      class="full-size-modal-wrapper__square-container full-size-modal-wrapper__square-container--clickable"
      routerLink="/"
      (click)="onCloseClick(true)"
    >
      <div class="full-size-modal-wrapper__picture"></div>
    </button>
    <span class="full-size-modal-wrapper__title">
      {{ header }}
      @if (showEntity) {
        <app-modal-entity-caption class="full-size-modal-wrapper__caption"></app-modal-entity-caption>
      }
    </span>
    <div class="full-size-modal-wrapper__square-container">
      <button fdt-fab-button color="tertiary" size="large" (click)="onCloseClick()">
        <fdt-icon name="closeLine"></fdt-icon>
      </button>
    </div>
  </div>
  <div class="full-size-modal-wrapper__body">
    <ng-content></ng-content>
  </div>
</div>
