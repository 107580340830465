import { Injectable } from '@angular/core';

import {
  InboxMarkAllReadResponse,
  InboxMessagesResponse,
  InboxMessageState,
  MessageStatus,
} from '@app/core/models/interfaces/inbox.interface';
import { CourierService } from '@app/core/services/courier.service';
import { BehaviorSubject, EMPTY, map, Observable, tap } from 'rxjs';

type InboxMessagesStoreState = { [k in MessageStatus]: InboxMessageState };

const INITIAL_VALUE: InboxMessageState = {
  pageInfo: {
    hasNextPage: false,
    startCursor: null,
  },
  data: [],
  totalCount: 0,
  isBusy: false,
  markingAll: false,
};

@Injectable({
  providedIn: 'root',
})
export class InboxStoreService {
  private readonly _messagesStore$ = new BehaviorSubject<InboxMessagesStoreState>({
    read: INITIAL_VALUE,
    unread: INITIAL_VALUE,
  });

  unreadMessageStore$ = this._messagesStore$.asObservable().pipe(map(({ unread }) => unread));
  readMessageStore$ = this._messagesStore$.asObservable().pipe(map(({ read }) => read));

  wsMessage$ = this.courierService.wsMessage$;

  constructor(private courierService: CourierService) {}

  initWebSocket(clientId: string): void {
    this.courierService.initWebSocket(clientId);
  }

  getMessages(status: MessageStatus, after?: string | null): Observable<InboxMessagesResponse> {
    this.updateMessagesState((state) => ({
      ...state,
      [status]: {
        ...state[status],
        pageInfo: after ? state[status].pageInfo : INITIAL_VALUE.pageInfo,
        isBusy: true,
      },
    }));

    return this.courierService.getMessages(status, after).pipe(
      tap(({ data }) =>
        this.updateMessagesState((state) => ({
          ...state,
          [status]: {
            ...data.messages,
            data: after ? state[status].data.concat(data.messages.data) : data.messages.data,
            isBusy: false,
          },
        }))
      )
    );
  }

  markAllAsRead(): Observable<InboxMarkAllReadResponse> {
    this.updateMessagesState((state) => ({
      ...state,
      unread: {
        ...state.unread,
        markingAll: true,
        data: state.unread.data.map((m) => ({ ...m, read: new Date().toString() })),
      },
    }));

    return this.courierService.markAllAsRead();
  }

  removeAllReadNotifications(): void {
    this.updateMessagesState((state) => ({
      ...state,
      unread: INITIAL_VALUE,
    }));
  }

  markAsRead(messageId: string): Observable<void> {
    this.updateMessagesState((state) => ({
      ...state,
      unread: {
        ...state.unread,
        data: state.unread.data.map((m) => (m.messageId === messageId ? { ...m, read: new Date().toString() } : m)),
      },
    }));

    return this.courierService.markAsRead(messageId);
  }

  removeReadNotification(messageId: string): Observable<InboxMessagesResponse> {
    this.updateMessagesState((state) => ({
      ...state,
      unread: {
        ...state.unread,
        totalCount: state.unread.totalCount - 1,
        data: state.unread.data.filter((m) => m.messageId !== messageId),
      },
    }));

    const { pageInfo } = this._messagesStore$.getValue().unread;

    if (pageInfo.hasNextPage) {
      return this.getMessages('unread', pageInfo.startCursor);
    }

    return EMPTY;
  }

  disconnectWs(): void {
    this.courierService.disconnectWs();
  }

  /**
   * Updates the state of the messages store using a provided function.
   *
   * This method retrieves the current state from the `_messagesStore$` observable,
   * applies the `updateFn` function to compute a new state, and then pushes the
   * updated state back to the store.
   *
   * @param updateFn - A function that takes the current state of the messages store
   *                   (`InboxMessagesStoreState`) and returns a new state.
   */
  private updateMessagesState(updateFn: (currentState: InboxMessagesStoreState) => InboxMessagesStoreState): void {
    const currentState = this._messagesStore$.getValue();
    const newState = updateFn(currentState);
    this._messagesStore$.next(newState);
  }
}
