import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { ClientService } from '@app/core/services/client.service';
import { KycSelfieContextService } from '@app/core/services/kyc-selfie-context.service';
import { LayoutApiService } from '@app/layout/layout-api.service';
import { MsalService } from '@azure/msal-angular';
import { firstValueFrom } from 'rxjs';
import { EnvironmentLoaderService } from 'src/app/core/services';
import { KycApiService } from 'src/app/kyc-selfie/kyc-api.service';
import { UserDetailForKYCSelfieResponse } from 'src/app/kyc-selfie/shared/kyc-selfie.models';

export const kycSelfieGuard = async (): Promise<boolean> => {
  const router = inject(Router);
  const kycSelfieContextService = inject(KycSelfieContextService);
  const kycService = inject(KycApiService);
  const authService = inject(MsalService);
  const envService = inject(EnvironmentLoaderService);

  const layoutService = inject(LayoutApiService);
  const clientContextService = inject(ClientService);

  const userDetailForKycSelfie = kycSelfieContextService.getActiveKYCSelfieEntity();

  // We need to ensure that the service entity ID exists for the KYC guard to retrieve KYC info.
  if (!(await firstValueFrom(clientContextService.activeServiceEntity$))) {
    const clients = await firstValueFrom(layoutService.getClientAccessorList());
    clientContextService.start(clients);
  }

  if (!userDetailForKycSelfie) {
    // Unverified clients will get a 403 error
    try {
      const kycDetail = await firstValueFrom(kycService.getUserKycSelfieDetails());
      const idTokenClaims = authService.instance.getActiveAccount()?.idTokenClaims;

      kycSelfieContextService.setActiveKycSelfieEntity({
        fullName: kycDetail.fullName,
        dateOfBirth: kycDetail.dateOfBirth,
        email: idTokenClaims?.['signInNames.emailAddress'],
        kycSelfieStatus: kycDetail.kycSelfieStatus,
        onfidoApplicantId: kycDetail.onfidoApplicantId,
        kycSelfieCheckId: kycDetail.kycSelfieCheckId,
      } as UserDetailForKYCSelfieResponse);
      kycSelfieContextService.setInProgress(true);

      if (kycDetail.kycSelfieStatus === 'not-passed') {
        router.navigate(['/kyc-selfie']);
        return false;
      }

      return true;
    } catch (e) {
      router.navigate(['/kyc-selfie']);
      return false;
    }
  } else if (
    envService.environment['enableAllKycChecks'] &&
    userDetailForKycSelfie!.kycSelfieStatus !== 'submitted' &&
    userDetailForKycSelfie!.photoIdVerificationStatus !== 'submitted'
  ) {
    // Will be a future story disable users from logging in, even for pending result
    router.navigate(['/kyc-selfie']);
    return false;
  } else if (userDetailForKycSelfie!.kycSelfieStatus === 'not-passed') {
    router.navigate(['/kyc-selfie']);
    return false;
  } else if (
    userDetailForKycSelfie!.kycSelfieStatus === 'passed' ||
    userDetailForKycSelfie!.kycSelfieStatus === 'submitted'
  ) {
    return true;
  }

  return true;
};
