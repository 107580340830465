import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { assetsResolver } from '@app/core/assets.resolver';
import { kycSelfieGuard } from '@app/core/guards/kyc-selfie-guard';
import { rsnSupportedCurrenciesResolver } from '@app/core/rsn-supported-currencies.resolver';
import { NoopComponent } from '@app/layout/noop-component/noop.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { DevComponent } from 'src/app/dev/dev/dev.component';
import { KycApiService } from 'src/app/kyc-selfie/kyc-api.service';

import { countriesResolver } from './core/country.resolver';
import { documentTypesResolver } from './core/document-types.resolver';
import { cardGuard } from './core/guards/card-guard';
import { ApplicationSettingsService } from './core/services';
import { userInfoResolver } from './core/user-info.resolver';
import { InactiveClientComponent } from './inactive-client/inactive-client.component';
import { ChangeClientServiceComponent } from './layout/change-client-service/change-client-service.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { RedirectErrorComponent } from './redirect-error/redirect-error.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [kycSelfieGuard],
    canActivateChild: [MsalGuard, kycSelfieGuard],
    resolve: {
      assets: assetsResolver,
      countryCodes: countriesResolver,
      userInfo: userInfoResolver,
      documentTypes: documentTypesResolver,
      rsnSupportedCurrencies: rsnSupportedCurrenciesResolver,
      appSettings: (): Observable<boolean> => inject(ApplicationSettingsService).init(),
    },
    children: [
      {
        path: '',
        component: LayoutComponent,
        children: [
          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          {
            path: 'noop-component',
            component: NoopComponent,
          },
          {
            path: 'activity',
            loadChildren: () => import('./activity/activity.module').then((m) => m.ActivityModule),
          },
          {
            path: 'asset-holdings',
            loadChildren: () => import('./asset-holding/asset-holding.module').then((m) => m.AssetHoldingModule),
          },
          {
            path: 'asset-transfer',
            loadChildren: () => import('./asset-transfer/asset-transfer.module').then((m) => m.AssetTransferModule),
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
          },
          {
            path: 'otc',
            loadChildren: () => import('./otc/otc.module').then((m) => m.OtcModule),
          },
          {
            path: 'treasury-bills',
            loadChildren: () => import('./treasury-bill/treasury-bill.module').then((m) => m.TreasuryBillModule),
          },
          {
            path: 'dev',
            component: DevComponent,
          },
          {
            component: ChangeClientServiceComponent,
            path: 'change-client-service',
            title: 'Change Client & Service Suite',
          },
          {
            path: 'whitelist',
            loadChildren: () => import('./whitelist/whitelist.module').then((m) => m.WhitelistModule),
          },
          {
            path: 'client',
            loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
          },
          {
            path: 'card',
            canActivate: [cardGuard],
            loadChildren: () => import('./card/card.module').then((m) => m.CardModule),
          },
          {
            path: 'help-center',
            loadChildren: () => import('./help-center/help-center.module').then((m) => m.HelpCenterModule),
          },
        ],
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: '404',
        loadComponent: () =>
          import('./layout/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent),
      },
    ],
  },
  {
    path: 'kyc-selfie',
    canActivate: [MsalGuard],
    loadChildren: () => import('src/app/kyc-selfie/kyc-selfie.module').then((m) => m.KycSelfieModule),
  },
  {
    path: 'inactive-client',
    canActivate: [MsalGuard],
    component: InactiveClientComponent,
  },
  { path: 'redirecting', component: MsalRedirectComponent },
  { path: 'redirect-error', component: RedirectErrorComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [KycApiService],
})
export class AppRoutingModule {}
