import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, switchMap, take } from 'rxjs';

import { ApplicationSettingsService } from './application-settings.service';
import { ClientService } from './client.service';
import { EnvironmentLoaderService } from './environment-loader.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private clientService: ClientService,
    private envService: EnvironmentLoaderService,
    private appSettingsService: ApplicationSettingsService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let requestToForward = req;

    // I would suggest to keep async flow for resolver here
    // Some possible handling strategies if needed, you could create special function to retrieve active service entity with handling fallback
    // Or move resolving somewhere to APP_INITIALIZER
    return this.clientService.activeServiceEntity$.pipe(
      take(1),
      switchMap((serviceEntity) => {
        const clientContext = this.clientService.getClientSelection();

        if (req.url === '/q') {
          requestToForward = req.clone({
            setHeaders: {
              'x-courier-client-key': this.envService.environment['courierClientKey'] as string,
              'x-courier-user-id': `${this.appSettingsService.userId}_${clientContext!.clientId}`,
            },
          });

          return next.handle(requestToForward);
        }

        const serviceEntityId = `${serviceEntity?.id || clientContext?.serviceEntityId || ''}`;
        requestToForward = req.clone({
          setHeaders: {
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            Pragma: 'no-cache',
            Expires: '0',
            'Service-Entity-Id': serviceEntityId,
          },
        });

        return next.handle(requestToForward);
      })
    );
  }
}
