import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import { EntitySwitcherComponent } from '@app/layout/entity-switcher/entity-switcher/entity-switcher.component';
import { FullSizeModalWrapperComponent } from '@app/shared/full-size-modal-wrapper/full-size-modal-wrapper.component';

@Component({
  selector: 'app-entity-switcher-modal',
  standalone: true,
  imports: [FullSizeModalWrapperComponent, EntitySwitcherComponent],
  templateUrl: './entity-switcher-modal.component.html',
  styleUrl: './entity-switcher-modal.component.scss',
})
export class EntitySwitcherModalComponent {
  constructor(private dialogRef: MatDialogRef<FullSizeModalWrapperComponent>) {}

  onCloseModal(): void {
    this.dialogRef.close(true);
  }
}
