@switch (eventId) {
  @case ('ams.instruction.cancelled.maker') {
    Your {{ requestData.instructionType }} instruction created on {{ requestData.instructionCreatedDate }} has been
    cancelled by {{ requestData.checkerFullName }}. Instruction Reference Number:
    {{ requestData.instructionReferenceNumber }} Please create a new instruction.
  }
  @case ('ams.instruction.waitingforreview.checker') {
    A new {{ requestData.instructionType }} instruction made by
    {{ requestData.makerFullName }}
    has been created on
    {{ requestData.instructionCreatedDate }}. Instruction Reference Number:
    {{ requestData.instructionReferenceNumber }}.
  }
}
