import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  computed,
  DestroyRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ActiveTourState, TourHighlightConfig, TourStep } from '@app/tour/models/tour.interface';
import { TourService } from '@app/tour/tour.service';
import { TourStepContentComponent } from '@app/tour/tour-step-content/tour-step-content.component';
import { generateOrderedPosition } from '@app/tour/utils/generate-ordered-position.utils';
import { getArrowDirection } from '@app/tour/utils/get-arrow-direction.utils';
import { getArrowOffset } from '@app/tour/utils/get-arrow-offset.utils';
import { of, switchMap } from 'rxjs';

/**
 * Directive that highlights elements during an interactive tour and display step content.
 * It works with `TourService` to display overlays on specific elements as users navigate through a tour.
 *
 * @example
 * ```html
 *   <div appTourHighlight [th-tour-config]="{ step: 1, name: 'onboarding' }">
 * ```
 */
@Directive({
  selector: '[appTourHighlight]',
  standalone: true,
  host: {
    class: 'tour',
    '[class.tour--active]': 'isActiveSignal()',
  },
})
export class TourHighlightDirective implements OnDestroy, AfterViewInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'th-tour-config' }) tourConfig?: TourHighlightConfig;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'th-prerequisite-click-ele' }) prerequisiteClickElement?: HTMLElement;

  private overlayRefSignal = signal<OverlayRef | null>(null);
  isActiveSignal = computed(() => !!this.overlayRefSignal());

  get overlayRef(): OverlayRef | null {
    return this.overlayRefSignal();
  }

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private destroyRef: DestroyRef,
    private renderer: Renderer2,
    private tourService: TourService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngAfterViewInit(): void {
    this.initializeTourSubscription();
  }

  ngOnDestroy(): void {
    this.closeOverlay();
  }

  /**
   * Initializes the subscription to the tour service and handles the visibility of the overlay.
   */
  private initializeTourSubscription(): void {
    if (!this.tourConfig) {
      return;
    }

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(
        switchMap(({ matches }: BreakpointState) => (matches ? of(null) : this.tourService.activeTourState$)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((state) => this.handleTourState(state, this.tourConfig!));
  }

  /**
   * Handles the tour state to activate the step if it matches the current tour configuration.
   * Otherwise, it closes the overlay.
   *
   * @param state - The current active tour state.
   * @param tourConfig - The tour configuration associated with this directive.
   */
  private handleTourState(state: ActiveTourState | null, tourConfig: TourHighlightConfig): void {
    if (state && state.tour.name === tourConfig.name && state.step.id === tourConfig.step) {
      this.activateStep(state.step);
    } else {
      this.closeOverlay();
    }
  }

  /**
   * Activates step by opening the overlay on the specified element.
   * If there is a prerequisite element that needs to be clicked, it clicks that as well.
   *
   * @param step - The current step of the tour.
   */
  private activateStep(step: TourStep): void {
    const positions = this.getOverlayPositions(step);

    if (this.prerequisiteClickElement) {
      this.prerequisiteClickElement.click();
      setTimeout(() => this.openOverlay(positions), 100);
    } else {
      this.openOverlay(positions);
    }
  }

  /**
   * Generate ordered positions for the overlay based on the step's configuration.
   *
   * @param step - The current step of the tour.
   */
  private getOverlayPositions(step: TourStep): ConnectedPosition[] {
    const { preferredPositions, positionsOffset } = step;

    return generateOrderedPosition(preferredPositions, positionsOffset);
  }

  /**
   * Creates and attach an overlay positioned relative to the host element based on the specified positions.
   *
   * @param positions - An array of `ConnectedPosition` objects that define how the overlay should be positioned
   *                    relative to the host element.
   */
  private openOverlay(positions: ConnectedPosition[]): void {
    const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions(positions);
    const overlay = this.createOverlay(positionStrategy);
    this.overlayRefSignal.set(overlay);

    this.attachOverlayContent(overlay, positionStrategy);
  }

  /**
   * Attaches the tour step content component to the provided overlay and manages the positioning of the highlight
   * element and the arrow indicating the connection between the overlay and the target element.
   *
   * @param overlay - The reference to the overlay where the tour step content will be attached.
   * @param positionStrategy - The strategy used to position the overlay relative to the target element.
   */
  private attachOverlayContent(overlay: OverlayRef, positionStrategy: FlexibleConnectedPositionStrategy): void {
    const portal = new ComponentPortal(TourStepContentComponent);
    const componentRef = overlay.attach(portal);

    positionStrategy.positionChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((change) => {
      const element = this.createHighlightElement();
      this.overlayRef?.backdropElement?.appendChild(element);

      const arrowDirection = getArrowDirection(change.connectionPair);

      if (!arrowDirection || !componentRef) {
        return;
      }

      const arrowDistance = getArrowOffset(
        arrowDirection,
        this.elementRef.nativeElement,
        componentRef.location.nativeElement
      );
      componentRef.setInput('arrowDirection', arrowDirection);
      componentRef.setInput('arrowOffset', arrowDistance);
    });
  }

  /**
   * Creates an overlay with the specified position strategy and configuration.
   *
   * @param positionStrategy - The strategy defining how the overlay should be positioned.
   */
  private createOverlay(positionStrategy: PositionStrategy): OverlayRef {
    const overlayConfig = new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'tour__backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    return this.overlay.create(overlayConfig);
  }

  /**
   * Closes the currently active overlay and clears the reference to it.
   */
  private closeOverlay(): void {
    this.overlayRef?.detach();
    this.overlayRefSignal.set(null);
  }

  /**
   * Creates an HTML element that visually highlights the current element in the tour.
   */
  private createHighlightElement(): HTMLElement {
    const element = this.renderer.createElement('div');
    this.renderer.addClass(element, 'tour__highlight');

    this.applyHighlightElementStyles(element);
    return element;
  }

  /**
   * Applies styles to the highlight element to match the target element's dimensions and position.
   *
   * @param element - The highlight element to style.
   */
  private applyHighlightElementStyles(element: HTMLElement): void {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    const computedStyles = window.getComputedStyle(this.elementRef.nativeElement);

    const styles = {
      width: `${rect.width}px`,
      height: `${rect.height}px`,
      borderRadius: parseInt(computedStyles.borderRadius) > 0 ? computedStyles.borderRadius : '12px',
      top: `${rect.top}px`,
      left: `${rect.left}px`,
    };

    Object.entries(styles).forEach(([property, value]) => {
      this.renderer.setStyle(element, property, value);
    });
  }
}
