<div style="background-color: white">

  <p>dev works!</p>
  <fdt-form-field>
    <fdt-label>Test WIll</fdt-label>
    <span fdtPrefix>
      <fdt-icon name="arrowUpLine"></fdt-icon>
    </span>
    <fdt-dropdown
      specialType="currency"
      [multiple]="true"
      [items]="dataSource"
      [formControl]="formGroup.controls.dropdown"
    >
    </fdt-dropdown>
  </fdt-form-field>
  <h2>example 2</h2>

  <fdt-dropdown type="button" [items]="dataSource"> </fdt-dropdown>

  <code>
    {{ formGroup.controls.dropdown.value }}
  </code>


  <section style="margin: 30px 0;">
    <h2>Primary Buttons</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-button color="primary" size="large">Large</button>
      <button fdt-button color="primary" size="medium">Medium</button>
      <button fdt-button color="primary" size="small">Small</button>
      <button fdt-button color="primary"> <fdt-icon name="downloadLine"></fdt-icon> With Icon</button>
      <button fdt-button color="primary"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-button color="primary" [disabled]="true">Disabled</button>
      <button fdt-button color="primary" [loading]="true">Loading</button>
    </div>

    <h2>Secondary Buttons</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-button color="secondary" size="large">Large</button>
      <button fdt-button color="secondary" size="medium">Medium</button>
      <button fdt-button color="secondary" size="small">Small</button>
      <button fdt-button color="secondary"> <fdt-icon name="downloadLine"></fdt-icon> With Icon</button>
      <button fdt-button color="secondary"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-button color="secondary" [disabled]="true">Disabled</button>
      <button fdt-button color="secondary" [loading]="true">Loading</button>
    </div>

    <h2>Tertiary Buttons</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-button color="tertiary" size="large">Large</button>
      <button fdt-button color="tertiary" size="medium">Medium</button>
      <button fdt-button color="tertiary" size="small">Small</button>
      <button fdt-button color="tertiary"> <fdt-icon name="downloadLine"></fdt-icon> With Icon</button>
      <button fdt-button color="tertiary"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-button color="tertiary" [disabled]="true">Disabled</button>
      <button fdt-button color="tertiary" [loading]="true">Loading</button>
    </div>

    <h2>Accent Buttons</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-button color="accent" size="large">Large</button>
      <button fdt-button color="accent" size="medium">Medium</button>
      <button fdt-button color="accent" size="small">Small</button>
      <button fdt-button color="accent"> <fdt-icon name="downloadLine"></fdt-icon> With Icon</button>
      <button fdt-button color="accent"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-button color="accent" [disabled]="true">Disabled</button>
      <button fdt-button color="accent" [loading]="true">Loading</button>
    </div>

    <h2>Danger Buttons</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-button color="danger" size="large">Large</button>
      <button fdt-button color="danger" size="medium">Medium</button>
      <button fdt-button color="danger" size="small">Small</button>
      <button fdt-button color="danger"> <fdt-icon name="downloadLine"></fdt-icon> With Icon</button>
      <button fdt-button color="danger"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-button color="danger" [disabled]="true">Disabled</button>
      <button fdt-button color="danger" [loading]="true">Loading</button>
    </div>

    <h2>Ghost Buttons default</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-ghost-button size="large">Large</button>
      <button fdt-ghost-button  size="medium">Medium</button>
      <button fdt-ghost-button  size="small">Small</button>
      <button fdt-ghost-button > <fdt-icon name="downloadLine"></fdt-icon> With Icon</button>
      <button fdt-ghost-button> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-ghost-button [disabled]="true">Disabled</button>
      <button fdt-ghost-button [loading]="true">Loading</button>
    </div>

    <h2>Ghost Buttons ghostType with-icon</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-ghost-button size="large" ghostType="with-icon">Large</button>
      <button fdt-ghost-button  size="medium" ghostType="with-icon">Medium</button>
      <button fdt-ghost-button  size="small" ghostType="with-icon">Small</button>
      <button fdt-ghost-button ghostType="with-icon"> <fdt-icon name="downloadLine"></fdt-icon> With Icon</button>
      <button fdt-ghost-button ghostType="with-icon"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-ghost-button [disabled]="true" ghostType="with-icon">Disabled</button>
      <button fdt-ghost-button [loading]="true" ghostType="with-icon">Loading</button>
    </div>

    <h2>Ghost Buttons ghostType with-selector-grey-bg</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-ghost-button size="large" ghostType="with-selector-grey-bg">Large</button>
      <button fdt-ghost-button  size="medium" ghostType="with-selector-grey-bg">Medium</button>
      <button fdt-ghost-button  size="small" ghostType="with-selector-grey-bg">Small</button>
      <button fdt-ghost-button ghostType="with-selector-grey-bg"> <fdt-icon name="downloadLine"></fdt-icon> With Icon</button>
      <button fdt-ghost-button ghostType="with-selector-grey-bg"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-ghost-button [disabled]="true" ghostType="with-selector-grey-bg">Disabled</button>
      <button fdt-ghost-button [loading]="true" ghostType="with-selector-grey-bg">Loading</button>
    </div>

    <h2>Ghost Buttons ghostType with-selector-white-bg</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-ghost-button size="large" ghostType="with-selector-white-bg">Large</button>
      <button fdt-ghost-button  size="medium" ghostType="with-selector-white-bg">Medium</button>
      <button fdt-ghost-button  size="small" ghostType="with-selector-white-bg">Small</button>
      <button fdt-ghost-button ghostType="with-selector-white-bg"> <fdt-icon name="downloadLine"></fdt-icon> With Icon</button>
      <button fdt-ghost-button ghostType="with-selector-white-bg"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-ghost-button [disabled]="true" ghostType="with-selector-white-bg">Disabled</button>
      <button fdt-ghost-button [loading]="true" ghostType="with-selector-white-bg">Loading</button>
    </div>

    <h2>Fab Buttons small</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-fab-button size="small"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="secondary" size="small"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="tertiary" size="small"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="accent" size="small"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="danger" size="small"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button [disabled]="true" size="small"><fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button [loading]="true" size="small"><fdt-icon name="downloadLine"></fdt-icon></button>
    </div>


    <h2>Fab Buttons medium</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-fab-button size="medium"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="secondary" size="medium"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="tertiary" size="medium"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="accent" size="medium"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="danger" size="medium"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button [disabled]="true" size="medium"><fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button [loading]="true" size="medium"><fdt-icon name="downloadLine"></fdt-icon></button>
    </div>

    <h2>Fab Buttons large</h2>
    <div style="display: flex; gap: 20px; align-items: center">
      <button fdt-fab-button size="large"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="secondary" size="large"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="tertiary" size="large"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="accent" size="large"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button color="danger" size="large"> <fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button [disabled]="true" size="large"><fdt-icon name="downloadLine"></fdt-icon></button>
      <button fdt-fab-button [loading]="true" size="large"><fdt-icon name="downloadLine"></fdt-icon></button>
    </div>

  </section>


  <section style="margin: 100px 0; padding: 30px;" id="">
    <div class="ms-5 mt-5">
      <div>
        <h4>Radio with FormControl (for now here because used mat-radio with define styles on app level)</h4>
        <div>
          <div>With value not disabled</div>
          <mat-radio-group [formControl]="radioForm.controls.radio">
            @for (item of radioItems; track item) {
              <mat-radio-button class="fdt-radio-button" [value]="item.value">
                {{item.label}}
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
        <div>
          <div>With value disabled</div>
          <mat-radio-group [formControl]="radioForm.controls.radioDisabledWithValue">
            @for (item of radioItems; track item) {
              <mat-radio-button class="fdt-radio-button" [value]="item.value">
                {{item.label}}
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
        <div>
          <div>Without value disabled</div>
          <mat-radio-group [formControl]="radioForm.controls.radioDisabledWithoutValue">
            @for (item of radioItems; track item) {
              <mat-radio-button class="fdt-radio-button" [value]="item.value">
                {{item.label}}
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
      </div>
    </div>
  </section>
</div>
