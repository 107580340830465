import { inject } from '@angular/core';

import { AppUserService } from '@1stdigital/ng-sdk/core';
import { CommonApiService } from '@app/core/services/common-api.service';
import { forkJoin, Observable, tap } from 'rxjs';

import { ClientsAccessorApiService } from './services/clients-accessor-api.service';

export const assetsResolver = (): Observable<unknown> => {
  const commonApiService = inject(CommonApiService);
  const clientsAccessorApiService = inject(ClientsAccessorApiService);
  const appUserService = inject(AppUserService);

  return forkJoin([commonApiService.getAssets(), clientsAccessorApiService.getAssetMasterIds()]).pipe(
    tap(([assets, assetMasterIds]) => {
      appUserService.setAssets(assets);
      appUserService.setAppAvailableAssets(assetMasterIds);
    })
  );
};
