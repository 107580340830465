<div
  class="fdt-display-amount fdt-display-amount--size-{{ size }} fdt-display-amount--color-scheme-{{ colorScheme }}"
  [class.fdt-display-amount--negative]="!isZero && isNegative"
  [class.fdt-display-amount--positive]="!isZero && !isNegative"
  [class.fdt-display-amount--zero]="isZero"
>
  @if (showElementsConfig.showNegativeSign && isNegative && !isZero) {
    <span class="fdt-display-amount__sign">-</span>
  }

  @if (showElementsConfig.assetSymbol && assetSymbol) {
    <span class="fdt-display-amount__asset-icon">
      {{ assetSymbol }}
    </span>
  }

  <span class="fdt-display-amount__amount">{{ amountFormatted }}</span>

  @if (showElementsConfig.assetCode) {
    <span class="fdt-display-amount__asset-code">{{ amount?.assetSymbol }}</span>
  }
</div>
