@switch (eventId) {
  @case ('ams.instruction.rsn.failed.notenoughgasfee.creator') {
    Your instruction created on
    {{ requestData.instructionCreatedDate }} has failed to process as there are insufficient funds in your account to
    pay for the network fee. Please resend again. Reference Number: {{ requestData.instructionReferenceNumber }}.
  }
  @case ('ams.instruction.fiat.withdrawal.initiated.client') {
    A withdrawal instruction has been made for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} on {{ requestData.dateCreated }}.
  }
  @case ('ams.instruction.fiat.withdrawal.completed.client') {
    Your withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} on {{ requestData.dateCreated }} is completed.
  }
  @case ('ams.instruction.fiat.payment.inprogress.client') {
    Your payment instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} to {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} on
    {{ requestData.dateCreated }} is in progress.
  }
  @case ('ams.instruction.fiat.payment.completed.client') {
    Your payment instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} to {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} on
    {{ requestData.dateCreated }} has been completed
  }
  @case ('ams.instruction.fiat.payment.cancelled.client') {
    Your Payment instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} to {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} on
    {{ requestData.dateCreated }} has been cancelled.
  }
  @case ('ams.instruction.fiat.payment.initiated.client') {
    A payment instruction (reference code:{{ requestData.referenceNumber }}) has been made for
    {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.bankAccountHolderName }} -
    {{ requestData.bankName }} on {{ requestData.dateCreated }}.
  }
  @case ('ams.instruction.fiat.deposit.completed.client') {
    Your deposit instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.amount }}
    {{ requestData.currency }} created on
    {{ requestData.dateCreated }}
    has been completed.
  }
  @case ('ams.instruction.fiat.withdrawal.cancelled.client') {
    Your withdrawal instruction for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} on {{ requestData.dateCreated }} is cancelled.
  }
  @case ('ams.instruction.fiat.payment.rejected.client') {
    Your Payment instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} to {{ requestData.bankAccountHolderName }} - {{ requestData.bankName }} on
    {{ requestData.dateCreated }} has been rejected.
  }
  @case ('ams.instruction.fiat.deposit.initiated.client') {
    Your deposit instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.amount }}
    {{ requestData.currency }} created on {{ requestData.dateCreated }}
  }
  @case ('ams.instruction.fiat.deposit.inprogress.client') {
    Your deposit instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.amount }}
    {{ requestData.currency }} created on
    {{ requestData.dateCreated }}
    is now in progress.
  }
  @case ('ams.instruction.fiat.deposit.cancelled.client') {
    Your deposit instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.amount }}
    {{ requestData.currency }} created on {{ requestData.dateCreated }} has been cancelled
  }
  @case ('ams.instruction.crypto.payment.completed.client') {
    Your payment instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} to {{ requestData.address }} on {{ requestData.dateCreated }} is completed.
  }
  @case ('ams.instruction.crypto.payment.cancelled.client') {
    Your payment instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} to {{ requestData.address }} on {{ requestData.dateCreated }} has been cancelled.
  }
  @case ('ams.instruction.crypto.deposit.completed.client') {
    Your deposit instruction (reference code: {{ requestData.referenceNumber }}) for {{ requestData.amount }}
    {{ requestData.currency }} created on {{ requestData.dateCreated }} has been completed.
  }
  @case ('ams.instruction.crypto.deposit.cancelled.client') {
    Your deposit instruction (reference code:{{ requestData.referenceNumber }}) {{ requestData.currency }}
    {{ requestData.amount }} to {{ requestData.packageName }} - {{ requestData.serviceAccountNumber }} on
    {{ requestData.dateCreated }} has been cancelled.
  }
  @case ('ams.instruction.crypto.withdrawal.cancelled.client') {
    Your withdrawal instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} to {{ requestData.address }} on {{ requestData.dateCreated }} has been cancelled.
  }
  @case ('ams.instruction.crypto.withdrawal.completed.client') {
    Your withdrawal instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} to {{ requestData.address }} on {{ requestData.dateCreated }} is completed.
  }
  @case ('ams.instruction.crypto.deposit.initiated.client') {
    A deposit instruction (reference code:{{ requestData.referenceNumber }}) has been created for
    {{ requestData.currency }} {{ requestData.amount }} to {{ requestData.packageName }} -
    {{ requestData.serviceAccountNumber }} on {{ requestData.dateCreated }}.
  }
  @case ('ams.instruction.crypto.withdrawal.initiated.client') {
    A withdrawal instruction has been created for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.address }} on {{ requestData.dateCreated }}.
  }
  @case ('ams.instruction.crypto.payment.initiated.client') {
    A payment instruction has been created for {{ requestData.currency }} {{ requestData.amount }} to
    {{ requestData.address }} on {{ requestData.dateCreated }}.
  }
  @case ('ams.instruction.rsn.completed.client.sender') {
    We’ve debited your account to your RSN Transfer instruction and your transfer has successfully been transferred.
  }
  @case ('ams.instruction.rsn.completed.client.receiver') {
    You've received funds in your account from {{ requestData.fromPackageName }} -
    {{ requestData.fromServiceAccountNumber }} for {{ requestData.amount }} {{ requestData.currency }} transferred on
    {{ requestData.dateUpdated }}.
  }
  @case ('ams.instruction.rsn.initiated.client') {
    An RSN payment (reference code:{{ requestData.referenceNumber }}) has been made for {{ requestData.currency }}
    {{ requestData.amount }} from {{ requestData.fromPackageName }} - {{ requestData.fromServiceAccountNumber }} to
    {{ requestData.toPackageName }} - {{ requestData.toServiceAccountNumber }} on {{ requestData.dateCreated }}.
  }
  @case ('ams.instruction.rsn.inprogress.client') {
    Your RSN instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} from {{ requestData.fromPackageName }} - {{ requestData.fromServiceAccountNumber }} to
    {{ requestData.toPackageName }} - {{ requestData.toServiceAccountNumber }} on {{ requestData.dateCreated }} is now
    in progress.
  }
  @case ('ams.instruction.rsn.completed.client') {
    Your RSN instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} from {{ requestData.fromPackageName }} - {{ requestData.fromServiceAccountNumber }} to
    {{ requestData.toPackageName }} - {{ requestData.toServiceAccountNumber }} on {{ requestData.dateCreated }} has been
    completed.
  }
  @case ('ams.instruction.rsn.cancelled.client') {
    Your RSN instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} from {{ requestData.fromPackageName }} - {{ requestData.fromServiceAccountNumber }} to
    {{ requestData.toPackageName }} - {{ requestData.toServiceAccountNumber }} on {{ requestData.dateCreated }} has been
    cancelled.
  }
  @case ('ams.instruction.otc.initiated.client') {
    An OTC instruction (reference code:{{ requestData.referenceNumber }}) has been created for
    {{ requestData.sourceAmount }} {{ requestData.sourceCurrencySymbol }} to {{ requestData.destinationAmount }}
    {{ requestData.destinationCurrencySymbol }} on {{ requestData.dateCreated }}.
  }
  @case ('ams.instruction.otc.completed.client') {
    Your OTC instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.sourceAmount }}
    {{ requestData.sourceCurrencySymbol }} to
    {{ requestData.destinationAmount }}
    {{ requestData.destinationCurrencySymbol }} on {{ requestData.dateCreated }} is completed.
  }
  @case ('ams.instruction.rsn.completed.client') {
    Your RSN instruction (reference code:{{ requestData.referenceNumber }}) for {{ requestData.currency }}
    {{ requestData.amount }} from {{ requestData.fromPackageName }} - {{ requestData.fromServiceAccountNumber }} to
    {{ requestData.toPackageName }} - {{ requestData.toServiceAccountNumber }} on {{ requestData.dateCreated }} has been
    completed.
  }
}
