import { ConnectedPosition } from '@angular/cdk/overlay';

export type TourConfigPosition = 'right' | 'left' | 'top' | 'bottom';

export const tourConfigPositionsMap: Record<TourConfigPosition, ConnectedPosition> = {
  right: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    offsetX: 20,
    offsetY: 0,
  },
  left: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetX: -20,
    offsetY: 0,
  },
  top: {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetX: 0,
    offsetY: -20,
  },
  bottom: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetX: 0,
    offsetY: 20,
  },
};

export type TourPositionsOffset = {
  [key in TourConfigPosition]?: { offsetY?: number; offsetX?: number };
};
