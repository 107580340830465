<div class="inbox-list">
  <div class="inbox-list__title">
    <span>
      Notifications
      @if (unreadMessagesVMSignal().totalCount > 0) {
        <span class="inbox-list__message-count">
          {{ unreadMessagesVMSignal().totalCount }}
        </span>
      }
    </span>
    <button fdt-ghost-button class="inbox-list__close-icon" (click)="closeWidget.emit()">
      <fdt-icon name="closeLine" size="24"></fdt-icon>
    </button>
  </div>
  <div class="inbox-list__content">
    <fdt-tab-group
      [selectedIndex]="tabSelectedIndex"
      (selectedTabChange)="onSelectedTabChange($event)"
      class="gray-bg inbox-list__tab-group"
    >
      <fdt-tab>
        <ng-template fdtTabLabel>New</ng-template>

        @if (!unreadMessagesVMSignal().totalCount) {
          <div class="inbox-list__empty-view">
            <app-empty-view
              header="0 new Notifications"
              subtitle="There aren't any updates with the portal"
            ></app-empty-view>
          </div>
        } @else if (unreadMessagesVMSignal().isBusy && !unreadMessagesVMSignal().pageInfo.startCursor) {
          <!-- Meaning is fetching the first page -->

          <div class="inbox-list__sekeleton-group">
            <app-inbox-skeleton></app-inbox-skeleton>
            <app-inbox-skeleton></app-inbox-skeleton>
            <app-inbox-skeleton></app-inbox-skeleton>
          </div>
        } @else {
          <app-inbox-messages
            [messagesVM]="unreadMessagesVMSignal()"
            (loadMore)="loadMoreUnreadMessages()"
            (markAsRead)="onMarkAsRead($event)"
            (clearRead)="onClearRead($event)"
            (instructionReview)="onInstructionReview($event)"
          ></app-inbox-messages>
        }
      </fdt-tab>
      <fdt-tab>
        <ng-template fdtTabLabel>Read</ng-template>

        @if (!readMessagesVMSignal().totalCount && !readMessagesVMSignal().isBusy) {
          <div class="inbox-list__empty-view">
            <app-empty-view
              header="0 new Notifications"
              subtitle="There aren't any updates with the portal"
            ></app-empty-view>
          </div>
        } @else if (readMessagesVMSignal().isBusy && !readMessagesVMSignal().pageInfo.startCursor) {
          <!-- Meaning is fetching the first page -->
          <div class="inbox-list__sekeleton-group">
            <app-inbox-skeleton></app-inbox-skeleton>
            <app-inbox-skeleton></app-inbox-skeleton>
            <app-inbox-skeleton></app-inbox-skeleton>
          </div>
        } @else {
          <app-inbox-messages
            [messagesVM]="readMessagesVMSignal()"
            (loadMore)="loadMoreReadMessages()"
            (markAsRead)="onMarkAsRead($event)"
          ></app-inbox-messages>
        }
      </fdt-tab>

      <fdt-tab [disabled]="true">
        <ng-template fdtTabLabel>
          @if (tabSelectedIndex === 0 && unreadMessagesVMSignal().totalCount) {
            <button class="button-1" fdt-ghost-button ghostType="with-icon" (click)="onMarkAllAsRead($event)">
              <fdt-icon name="checkDoubleLine" size="20"></fdt-icon>Mark all as read
            </button>
          }
        </ng-template>
      </fdt-tab>
    </fdt-tab-group>
  </div>
</div>
