import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Amount, AppUserService, FormatterService } from '@1stdigital/ng-sdk/core';
import { assetSymbolToSymbolMap } from '@1stdigital/ng-sdk/shared';
import { shorthandAmount, ShorthandGain } from '@app/shared/display-property/utils';

@Component({
  selector: 'app-display-amount',
  standalone: true,
  imports: [],
  templateUrl: './display-amount.component.html',
  styleUrl: './display-amount.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAmountComponent {
  amount?: Amount;
  assetSymbol?: string;
  amountFormatted?: string;
  isNegative = false;
  isZero = false;
  showElementsConfig: ShowAmountElementsConfig = this.getElementsConfig();

  // Shorthand billions and more
  @Input() shorthandGain: ShorthandGain = 1e9;
  @Input() colorScheme: DisplayAmountColorScheme = 'default';
  // Task to integrate and test more various amount sizes instead of classes in components
  // https://1stdigital.atlassian.net/browse/STX-2355
  @Input() size: DisplayAmountSize = 'medium';
  // isFiat is mainly for Card txs - since card can be swiped globally & these global currencies may not be registered in our list of assets
  @Input() isFiat: boolean = false;

  @Input() set value(amount: Amount) {
    this.amount = amount;
    this.isZero = amount.amount === 0;
    const asset = this.appUserService.getAsset({
      assetSymbol: amount.assetSymbol,
    });

    if (asset && asset.assetClass === 'cash') {
      this.assetSymbol = assetSymbolToSymbolMap[asset.assetSymbol];
    } else if (this.isFiat) {
      this.assetSymbol = assetSymbolToSymbolMap[this.amount.assetSymbol];
    }

    this.amountFormatted = this.formatValue(amount);

    this.ensureIsNegativeValue(amount.amount, this._markAsNegative);
  }

  /**
   * Ignore the positive value of the amount and show it as negative;
   * Flag is ignored for zeroes and already negative amounts
   *
   * Usually used for **non-Transfer-in** instructions
   */
  @Input() set markAsNegative(value: boolean) {
    this._markAsNegative = value;
    this.ensureIsNegativeValue(this.amount?.amount || 0, this._markAsNegative);
  }

  private _markAsNegative = false;

  constructor(
    private formatterService: FormatterService,
    private appUserService: AppUserService
  ) {}

  private getElementsConfig(): ShowAmountElementsConfig {
    return {
      assetSymbol: true,
      assetCode: true,
      showNegativeSign: true,
    };
  }

  /**
   * - markAsNegative: true, amount.amount < 0, => isNegative: true
   * - markAsNegative: false, amount.amount < 0, => isNegative: true
   * - markAsNegative: true, amount.amount > 0, => isNegative: true
   * - markAsNegative: false, amount.amount >= 0, => isNegative: false
   */
  private checkIsNegativeByRules(amount: number, markAsNegative: boolean): boolean {
    if (amount < 0) {
      return true;
    }

    if (amount === 0) {
      return false;
    }

    return markAsNegative;
  }

  /**
   * Ensure that the value should be shown as positive or negative;
   *
   * Amount may be processed as positive value on API layer,
   * but should be displayed as negative on UI by business rules
   *
   * Example: activity instruction amounts, reserved amounts
   */
  private ensureIsNegativeValue(amount: number, markNegative: boolean): void {
    this.isNegative = this.checkIsNegativeByRules(amount, markNegative);
  }

  private formatValue(amount: Amount): string {
    if (!this.shorthandGain) {
      return this.formatterService.formatAmountAsNumber(amount, true);
    }

    const result = shorthandAmount(amount.amount, { digits: 3, shorthandGain: this.shorthandGain });

    if (!result.isChanged) {
      return this.formatterService.formatAmountAsNumber(amount, true);
    }

    return result.value;
  }
}

/**
 *
 * NOTE: if we need to display positive signs by design in the future,
 * I propose to extend this config and add showPositiveSign?: boolean;
 */
export interface ShowAmountElementsConfig {
  assetSymbol?: boolean;
  // Currently it is a short code of currencies in our system
  assetCode?: boolean;
  showNegativeSign?: boolean;
}

/**
 * - default - colors that most frequently used (black and gray)
 * - mono-primary - mono color black, Example: headings
 * - mono-secondary - mono color gray, Example: captions
 * - highlighted - conditional highlighted colors for incomes, outcomes, etc, Example: Income instructions and transactions
 */
type DisplayAmountColorScheme = 'default' | 'mono-primary' | 'mono-secondary' | 'highlighted';
type DisplayAmountSize = 'small' | 'medium' | 'large' | 'xl';
