<!-- Notification Center -->
<div class="inbox-widget" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="notiCenterMenu">
  <div class="inbox-widget__bell" [class.inbox-widget__bell--unread]="hasNewMessage">
    <img class="inbox-widget__image" [src]="'assets/images/bell.svg'" alt="inbox" />
  </div>
</div>

<mat-menu
  #notiCenterMenu="matMenu"
  backdropClass="fdt-dialog-cdk-overlay-backdrop"
  xPosition="after"
  yPosition="below"
  class="fdt-mat-mdc-menu-panel fdt-dropdown-panel inbox-widget__menu-panel"
>
  <div (keydown)="$event.stopPropagation()" (click)="$event.stopPropagation()" tabindex="0">
    <app-inbox-message-layout (closeWidget)="closeWidget(menuTrigger)"></app-inbox-message-layout>
  </div>
</mat-menu>
