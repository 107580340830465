import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper, CdkStepperModule, STEP_STATE } from '@angular/cdk/stepper';
import { NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MatExpansionModule } from '@angular/material/expansion';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { DialogService } from '@1stdigital/ng-sdk/dialog';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';

import { DisclaimerModalComponent } from '../disclaimer-modal/disclaimer-modal.component';
import { StepComponent } from './step/step.component';

/**
 * Component representing a stepper group.
 * This component extends `CdkStepper` interface.
 * It manages a series of steps and handles step transitions, disclaimers, and submission.
 *
 * @example
 * <!-- Basic usage example -->
 * <app-stepper-group [showDisclaimer]="true">
 *   <app-step [header]="'Step one'">
 *     <p>Content for Step 1</p>
 *   </app-step>
 *   <app-step [header]="'Step two'">
 *     <p>Content for Step 2</p>
 *   </app-step>
 * </app-stepper-group>
 *
 * @example
 * <!-- Stepper with multi form controls -->
 * <app-stepper-group (submitEvent)="submitEventHandler()">
 *   <app-step
 *     [header]="'Step one'"
 *     [stepControl]="controlOne"
 *     (next)="nextOneHandler()"
 *     (edit)="editHandler()"
 *   >
 *     <p>Content for Step 1</p>
 *   </app-step>
 *   <app-step
 *     [header]="'Step two'"
 *     [stepControl]="controlTwo"
 *     (next)="nextTwoHandler()"
 *     (edit)="editTwoHandler()"
 *   >
 *     <p>Content for Step 2</p>
 *   </app-step>
 *   <app-step
 *     [header]="'Step 3'"
 *     [stepControl]="controlThree"
 *     (next)="nextThreeHandler()"
 *     (edit)="editThreeHandler()"
 *   >
 *     <p>Content for Step 3</p>
 *   </app-step>
 * </app-stepper-group>
 *
 * @example
 * <!-- Stepper with multi form controls and custom footer actions -->
 * <app-stepper-group (submitEvent)="submitEventHandler()">
 *   <app-step [header]="'Step one'" [stepControl]="controlOne">
 *     <p>Content for Step 1</p>
 *     <ng-template #editActionsTemplateRef let-step>
 *       <button fdt-button color="secondary" (click)="step.onCancel()">Cancel</button>
 *       <button
 *         fdt-button
 *         color="primary"
 *         [disabled]="step.stepDisabled"
 *         [loading]="isLoading"
 *         (click)="onEditHandler(step)"
 *       >
 *         Edit
 *       </button>
 *     </ng-template>
 *     <ng-template #nextActionTemplateRef let-step>
 *       <button
 *         fdt-button
 *         color="primary"
 *         [disabled]="step.stepDisabled || isLoading"
 *         [loading]="isLoading"
 *         (click)="onNextHandler(step)"
 *       >
 *         Next
 *       </button>
 *     </ng-template>
 *   </app-step>
 *   <app-step
 *     [header]="'Step two'"
 *     [stepControl]="controlTwo"
 *     (next)="nextTwoHandler()"
 *     (edit)="editTwoHandler()"
 *   >
 *     <p>Content for Step 2</p>
 *   </app-step>
 *   <app-step
 *     [header]="'Step 3'"
 *     [stepControl]="controlThree"
 *     (next)="nextThreeHandler()"
 *     (edit)="editThreeHandler()"
 *   >
 *     <p>Content for Step 3</p>
 *   </app-step>
 * </app-stepper-group>
 */
@Component({
  selector: 'app-stepper-group',
  standalone: true,
  imports: [CdkStepperModule, NgTemplateOutlet, MatExpansionModule, FdtButtonModule, FdtIconModule],
  templateUrl: './stepper-group.component.html',
  styleUrl: './stepper-group.component.scss',
  providers: [{ provide: CdkStepper, useExisting: StepperGroupComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperGroupComponent extends CdkStepper implements OnInit, OnChanges, AfterContentInit {
  @ContentChildren(StepComponent, { descendants: true }) override _steps!: QueryList<StepComponent>;
  override readonly steps: QueryList<StepComponent> = new QueryList<StepComponent>();

  /**
   * @Output() submitEvent: EventEmitter<void>
   *   Emitted when the last step button is clicked and all steps are complete.
   */
  @Output() submitEvent = new EventEmitter<void>();
  @Input() showDisclaimer = false;
  @Input() defaultSelectedIndex: number = 0;
  @Input() submitDisabled = false;

  /**
   * Reference to last selected step.
   * @type {StepComponent}
   */
  lastSelectedStep = this.selected;

  /**
   * Reference to step that we want to edit.
   * @type {StepComponent}
   */
  editStep: StepComponent | null = null;

  constructor(
    @Optional() dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>,
    private destroyRef: DestroyRef,
    private modalService: DialogService
  ) {
    super(dir, changeDetectorRef, elementRef);
  }

  ngOnInit(): void {
    this.selectedIndex = this.defaultSelectedIndex;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defaultSelectedIndex']) {
      this.selectedIndex = this.defaultSelectedIndex;
    }
  }

  override ngAfterContentInit(): void {
    super.ngAfterContentInit();

    // Mark the component for change detection whenever the content children query changes
    this.steps.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this._stateChanged();
    });
  }

  onDisclaimer(): void {
    this.modalService.open(DisclaimerModalComponent);
  }

  onEditStep(event: MouseEvent, step: StepComponent): void {
    event.preventDefault();
    event.stopPropagation();
    this.editStep = step;

    step.setState(STEP_STATE.EDIT);
    step.select();
  }

  onSubmit(): void {
    this.steps.last.setState(STEP_STATE.DONE);
    this.submitEvent.emit();
  }
}
