import { inject, Injectable } from '@angular/core';

import { ApplicationSettingsService } from '@app/core/services';
import { LogoutService } from '@app/core/services/logout.service';
import { LayoutModuleSettings, TourSettings } from '@app/layout/layout-module.settings';
import { ActiveTourState, Tour, TourName } from '@app/tour/models/tour.interface';
import { TourChangeStates } from '@app/tour/models/tour-change-state';
import { LAYOUT_TOURS } from '@app/tour/tours.config';
import { BehaviorSubject, combineLatest, map, Subject } from 'rxjs';

/**
 * Service that manages the state of interactive tours within the application.
 * It allows starting, navigating, and ending tours that guide users through steps of a specific tour.
 */
@Injectable({
  providedIn: 'root',
})
export class TourService {
  private appSettingsService = inject(ApplicationSettingsService);
  private logoutService = inject(LogoutService);

  private readonly _activeTourState$ = new BehaviorSubject<ActiveTourState | null>(null);
  activeTourState$ = this._activeTourState$.asObservable();

  private readonly _stateChanges$ = new Subject<{ name: TourName; state: TourChangeStates }>();
  stateChanges$ = this._stateChanges$.asObservable();

  private readonly _isTourIntroOpened$ = new BehaviorSubject<boolean>(false);
  isTourIntroOpened$ = this._isTourIntroOpened$.asObservable();

  hasActiveTour$ = combineLatest([this.activeTourState$, this.isTourIntroOpened$]).pipe(
    // In case the intro is opened, we should consider it as an active tour.
    map(([state, isIntroOpened]) => !!state || isIntroOpened)
  );

  get activeTourState(): ActiveTourState | null {
    return this._activeTourState$.getValue();
  }

  /**
   * Starts a tour based on the tour name, which begins from the first step.
   * @param tourName
   */
  startTour(tourName: TourName): void {
    const tour = this.findTourByName(tourName);

    if (tour && tour.steps.length > 0) {
      const stepIndex = 0;
      this.setActiveTourState(tour, stepIndex);
      this._stateChanges$.next({ name: tour.name, state: TourChangeStates.Started });
    }
  }

  /**
   * Marks the current tour intro as opened.
   */
  startTourIntro(): void {
    this._isTourIntroOpened$.next(true);
  }

  /**
   * Marks the current tour intro as closed.
   */
  endTourIntro(): void {
    this._isTourIntroOpened$.next(false);
  }

  /**
   * Move to the next step in the current tour.
   */
  nextStep(): void {
    const activeTourState = this._activeTourState$.getValue();

    if (!activeTourState) {
      return;
    }

    const { tour, stepIndex } = activeTourState;

    if (stepIndex < tour.steps.length - 1) {
      this.setActiveTourState(tour, stepIndex + 1);
      this._stateChanges$.next({ name: tour.name, state: TourChangeStates.StepChange });
    }
  }

  /**
   * Moves back to the previous step in the current tour.
   */
  previousStep(): void {
    const activeTourState = this._activeTourState$.getValue();

    if (!activeTourState) {
      return;
    }

    const { tour, stepIndex } = activeTourState;

    if (stepIndex > 0) {
      this.setActiveTourState(tour, stepIndex - 1);
      this._stateChanges$.next({ name: tour.name, state: TourChangeStates.StepChange });
    }
  }

  /**
   * Ends the current tour, clearing the active tour state.
   */
  endTour(isCompleted: boolean = false): void {
    const activeTour = this._activeTourState$.getValue();

    if (activeTour) {
      this._stateChanges$.next({
        name: activeTour.tour.name,
        state: isCompleted ? TourChangeStates.Complete : TourChangeStates.Skipped,
      });
    }

    this._activeTourState$.next(null);
  }

  updateTourSettings(state: TourChangeStates, tourName: TourName, maxSkips: number = 2): void {
    const layoutSettings = this.appSettingsService.getSettings<LayoutModuleSettings>('layout');

    if (state === TourChangeStates.Skipped || state === TourChangeStates.Complete) {
      const tourSettings: TourSettings = {
        skip: 0,
        finish: false,
        ...layoutSettings.tourGuide?.[tourName],
        lastSessionId: this.logoutService.getAuthTime(),
      };

      if (state === TourChangeStates.Skipped) {
        tourSettings.skip++;
      }

      if (state === TourChangeStates.Complete) {
        tourSettings.finish = true;
        tourSettings.skip = 0;
      }

      if (tourSettings.skip === maxSkips) {
        tourSettings.finish = true;
      }

      layoutSettings.tourGuide[tourName] = tourSettings;
      this.appSettingsService.saveSettings('layout', layoutSettings).subscribe();
    }
  }

  /**
   * Searches for a tour by its name within the config file.
   * @param tourName - Tour Name
   * @private
   */
  private findTourByName(tourName: TourName): Tour | null {
    return LAYOUT_TOURS.find(({ name }) => name === tourName) ?? null;
  }

  private setActiveTourState(tour: Tour, stepIndex: number): void {
    const step = tour.steps[stepIndex];
    this._activeTourState$.next({ tour, step, stepIndex });
  }
}
