import { inject } from '@angular/core';

import { AssetTransferApiService } from '@app/asset-transfer/shared/asset-transfer-api.service';
import { Observable, tap } from 'rxjs';

export const rsnSupportedCurrenciesResolver = (): Observable<unknown> => {
  const assetTransferApiService = inject(AssetTransferApiService);

  return assetTransferApiService.getRsnSupportedCurrencies().pipe(
    tap((currencies) => {
      assetTransferApiService.setRsnSupportedCurrencies(currencies);
    })
  );
};
