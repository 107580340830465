import { Component } from '@angular/core';

import { FdtSpinnerOverlayDirective } from '@1stdigital/ng-sdk/shared';

@Component({
  selector: 'app-redirect-error',
  standalone: true,
  imports: [FdtSpinnerOverlayDirective],
  templateUrl: './redirect-error.component.html',
  styleUrl: './redirect-error.component.scss',
})
export class RedirectErrorComponent {}
