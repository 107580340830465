import { inject } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { CountryService } from './services/country.service';

export const countriesResolver = (): Observable<unknown> => {
  const countryService = inject(CountryService);

  return countryService.getCountries().pipe(
    tap((countries) => {
      countryService.setCountries(countries);
    })
  );
};
