import { Injectable } from '@angular/core';

import { BaseApiService, DropdownItem } from '@1stdigital/ng-sdk/core';
import { Observable } from 'rxjs';

import { DocumentTypeGroup } from '../models/document-type-group.type';

@Injectable({
  providedIn: 'root',
})
export class FileStorageApiService extends BaseApiService {
  documentTypes?: Record<DocumentTypeGroup, DropdownItem[]>;

  getDocumentTypes(): Observable<Record<DocumentTypeGroup, DropdownItem[]>> {
    return this.get('file-storage/document-types');
  }

  setDocumentTypes(documentTypes: Record<DocumentTypeGroup, DropdownItem[]>): void {
    this.documentTypes = documentTypes;
  }

  getDocumentType(group: DocumentTypeGroup): DropdownItem[] {
    return this.documentTypes ? this.documentTypes[group] : [];
  }
}
