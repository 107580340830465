import { NgClass } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';

import { MatTooltip } from '@angular/material/tooltip';

import { Amount } from '@1stdigital/ng-sdk/core';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { StatusLabelComponent } from '@1stdigital/ng-sdk/shared';
import { accountStatusConfig, ClientStatus } from '@app/core/models';
import { DisplayAmountComponent } from '@app/shared/display-property/display-amount/display-amount.component';

@Component({
  selector: 'app-entity-switcher-option',
  standalone: true,
  imports: [FdtIconModule, NgClass, MatTooltip, DisplayAmountComponent, StatusLabelComponent],
  templateUrl: './entity-switcher-option.component.html',
  styleUrl: './entity-switcher-option.component.scss',
})
export class EntitySwitcherOptionComponent {
  @Input() status: ClientStatus = 'active';
  @Input() label: string = '';
  @Input() amount?: Amount;

  get isDisabled(): boolean {
    return this.status !== 'active';
  }

  readonly tooltipByStatus: { [key in ClientStatus]?: string } = {
    dormant:
      'This account and all related services are dormant. Contact our support or your Relationship Manager for details.',
    suspended: 'Service Suite suspended due to inactivity. Contact support or your Relationship Manager for details.',
    closed:
      'This account and all related services have been closed. Contact our support or your Relationship Manager for details.',
  };

  @HostListener('click', ['$event'])
  onComponentClick($event: PointerEvent): void {
    this.isDisabled && $event.stopPropagation();
  }

  protected readonly accountStatusConfig = accountStatusConfig;
}
