<div class="entity-switcher" [formGroup]="selectionForm">
  <div class="entity-switcher__row client" [class.entity-switcher__row--dropdown]="clients.length > maxVisibleAmount">
    <span class="entity-switcher__row-label">Select client</span>
    @switch (currentWidgetStatus) {
      @case (WidgetStatuses.Loading) {
        <ng-container *ngTemplateOutlet="dropdownLoadingTemplate"></ng-container>
      }
      @case (WidgetStatuses.Ready) {
        <ng-container *ngTemplateOutlet="selectClientTemplate"></ng-container>
      }
    }

    <ng-template #selectClientTemplate>
      @if (clients.length <= maxVisibleAmount) {
        <fdt-toggle-group [formControl]="selectionForm.controls.clientId" class="entity-switcher__clients-toggle">
          @for (client of clients; track client.value) {
            <app-toggle-item-container
              [value]="client.value"
              [emitValueChangeOnClick]="client.entity.status === 'active'"
            >
              <div
                [matTooltip]="
                  client.entity.status !== 'active'
                    ? 'Client suspended due to inactivity. Contact support or your Relationship Manager for details.'
                    : ''
                "
                matTooltipPosition="above"
              >
                <div class="client-card" [ngClass]="{ 'client-card--suspended': client.entity.status !== 'active' }">
                  {{ client.label }}
                  @if (client.entity.status !== 'active') {
                    <fdt-status-label
                      class="client-card__inactive-status"
                      [status]="client.entity.status"
                      size="small"
                      [config]="accountStatusConfig"
                    ></fdt-status-label>
                  }
                </div>
              </div>
            </app-toggle-item-container>
          }
        </fdt-toggle-group>
      } @else {
        <fdt-form-field class="entity-switcher__row-form-field">
          <fdt-dropdown [formControl]="selectionForm.controls.clientId" [items]="clients">
            <ng-template #optionTemplate let-option>
              <app-entity-switcher-option
                [status]="option.item.entity.status"
                [label]="option.item.label"
              ></app-entity-switcher-option>
            </ng-template>
          </fdt-dropdown>
        </fdt-form-field>
      }
    </ng-template>
  </div>

  <div
    class="entity-switcher__row entity"
    [class.entity-switcher__row--dropdown]="serviceEntities.length > maxVisibleAmount"
  >
    <span class="entity-switcher__row-label">Select service suite</span>
    @switch (currentWidgetStatus) {
      @case (WidgetStatuses.Loading) {
        <ng-container *ngTemplateOutlet="dropdownLoadingTemplate"></ng-container>
      }
      @case (WidgetStatuses.Ready) {
        <ng-container *ngTemplateOutlet="selectEntityTemplate"></ng-container>
      }
    }
    <ng-template #selectEntityTemplate>
      @if (serviceEntities.length <= maxVisibleAmount) {
        <fdt-toggle-group [formControl]="selectionForm.controls.serviceEntityId" class="entity-switcher__entity-toggle">
          @for (entity of serviceEntities; track entity.value) {
            <app-toggle-item-container [value]="entity.value" [emitValueChangeOnClick]="entity.entity.isActive">
              <div
                [matTooltip]="
                  !entity.entity.isActive
                    ? 'Service Suite suspended due to inactivity. Contact support or your Relationship Manager for details.'
                    : ''
                "
                matTooltipPosition="above"
              >
                <div class="entity-card" [ngClass]="{ 'entity-card--suspended': !entity.entity.isActive }">
                  <div class="entity-card__content">
                    <span class="entity-card__title">
                      {{ entity.label }}
                    </span>
                    <span class="entity-card__amount">
                      <app-display-amount
                        colorScheme="mono-secondary"
                        [value]="entity.entity.totalBalance"
                      ></app-display-amount>
                    </span>
                  </div>
                  @if (!entity.entity.isActive) {
                    <fdt-status-label
                      class="entity-card__inactive-status"
                      status="suspended"
                      size="small"
                      [config]="accountStatusConfig"
                    ></fdt-status-label>
                  }
                </div>
              </div>
            </app-toggle-item-container>
          }
        </fdt-toggle-group>
      } @else {
        <fdt-form-field class="entity-switcher__row-form-field">
          <fdt-dropdown
            uniquePanelClass="entity-switcher-panel"
            class="entity-dropdown"
            [formControl]="selectionForm.controls.serviceEntityId"
            [items]="serviceEntities"
            #dropdownComponent
          >
            <ng-template #triggerTemplate let-context>
              <button class="fdt-dropdown__trigger-button" fdt-ghost-button color="tertiary">
                <p class="fdt-dropdown__trigger-button__label entity-dropdown__btn-label">
                  {{ context.displayValue }}

                  @if (context.selection[0]; as selection) {
                    <span class="entity-dropdown__amount">
                      <app-display-amount
                        colorScheme="mono-secondary"
                        [value]="selection.entity.totalBalance"
                      ></app-display-amount>
                    </span>
                  }
                </p>
              </button>
            </ng-template>
            <ng-template #optionTemplate let-option>
              <app-entity-switcher-option
                [label]="option.item.label"
                [amount]="option.item.entity.totalBalance"
                [status]="option.item.disabled ? 'suspended' : 'active'"
              ></app-entity-switcher-option>
            </ng-template>
          </fdt-dropdown>
        </fdt-form-field>
      }
    </ng-template>
  </div>

  <div
    class="entity-switcher__row"
    [class.entity-switcher__row--lined-up]="
      clients.length > maxVisibleAmount && serviceEntities.length > maxVisibleAmount
    "
  >
    <fdt-checkbox [formControl]="selectionForm.controls.saveSelection">Set selection as default</fdt-checkbox>
    <button
      [disabled]="selectionForm.invalid"
      class="entity-switcher__submit-btn"
      fdt-button
      color="primary"
      size="large"
      (click)="onSelectionSubmit()"
    >
      Confirm
    </button>
  </div>
</div>

<ng-template #dropdownLoadingTemplate>
  <div class="entity-switcher__dropdown-loading">
    <ng-container *fdtSkeleton="true; count: 1; height: '20px'; animation: 'pulse'"></ng-container>
  </div>
</ng-template>
