import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialogModule } from '@angular/material/dialog';

import { DialogService } from '@1stdigital/ng-sdk/dialog';

import { EntitySwitcherModalComponent } from '../entity-switcher/entity-switcher-modal/entity-switcher-modal.component';

@Component({
  selector: 'app-change-client-service',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './change-client-service.component.html',
  styleUrl: './change-client-service.component.scss',
})
export class ChangeClientServiceComponent implements OnInit {
  constructor(
    private modalService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.modalService.openFullSize(EntitySwitcherModalComponent).subscribe(() => this.onEntitySwitch());
  }

  private onEntitySwitch(): void {
    this.router.navigate(['noop-component']).then(() => {
      this.router.navigate(['']);
    });
  }
}
